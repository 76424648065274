import axios, { AxiosResponse, AxiosError } from "axios";
import Cookies from "js-cookie";
import { getEnv } from "./env";

const api = axios.create({
  baseURL: getEnv("REACT_APP_API_URL"),
});

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove("token");
      Cookies.remove("unmark");
      window.location.href = "/sign-in";
      return new Promise(() => { });
    }
    return Promise.reject(error);
  }
);

export default api;
