import { DialogPermission } from "@/components/common";
import { ReactNode, useState } from "react";
import { Button } from "../resources";
import { useAppSelector } from "@/lib/hooks";

interface ButtonPermissionProps {
  disabled?: boolean;
  title: string;
  action?: () => Promise<void> | void;
  contentDialog?: string | ReactNode;
}

export const ButtonPermission = ({
  title,
  action,
  disabled,
  contentDialog = "",
}: ButtonPermissionProps) => {
  const isSuperAdmin = useAppSelector((state) => state.user.isSuperAdmin);

  const [openDialogPermission, setOpenDialogPermission] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          if (isSuperAdmin) {
            action && action();
          } else {
            setOpenDialogPermission(true);
          }
        }}
      >
        {title}
      </Button>
      <DialogPermission
        open={openDialogPermission}
        setOpen={setOpenDialogPermission}
        content={contentDialog}
      />
    </>
  );
};
