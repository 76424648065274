import { FormChangePassword } from "@/components/common";
import { useToast } from "@/components/resources/use-toast";
import { useAppSelector } from "@/lib/hooks";
import { UserService } from "@/services";
import { useState } from "react";

import { z } from "zod";

const formSchema = z
  .object({
    passwordCurrent: z
      .string()
      .refine((value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(value), {
        message:
          "영어 대문자 또는 소문자, 숫자를 조합하여 6자 이상으로 입력하세요.",
      }),
    passwordNew: z
      .string()
      .refine((value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(value), {
        message:
          "영어 대문자 또는 소문자, 숫자를 조합하여 6자 이상으로 입력하세요.",
      }),
    passwordConfirm: z
      .string()
      .refine((value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(value), {
        message:
          "영어 대문자 또는 소문자, 숫자를 조합하여 6자 이상으로 입력하세요.",
      }),
  })
  .refine((data) => data.passwordNew === data.passwordConfirm, {
    message: "새 비밀번호와 비밀번호 확인이 일치하지 않습니다",
    path: ["passwordConfirm"],
  });

export const ChangePasswordUser = () => {
  const { toast } = useToast();

  const { isSuperAdmin, username } = useAppSelector((state) => state.user);

  const [error, setError] = useState("");

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setError("");
    const { passwordNew: password_new, passwordCurrent: password_old } = values;
    try {
      await UserService.updatePassword(username, {
        password_new,
        password_old,
      });
      toast({
        description: "비밀번호가 성공적으로 변경되었습니다.",
      });
    } catch (error) {
      setError("현재 암호가 잘못되었습니다");
    }
  };

  return (
    <div className="space-y-2">
      <div>
        <h3 className="text-xl font-bold mb-3">
          {isSuperAdmin ? "Super Admin" : "Admin"} 패스워드 설정
        </h3>
        <p className="text-sm">
          영어 대문자 또는 소문자, 숫자를 조합하여 6자 이상으로 입력하세요.
        </p>
      </div>
      {error && <span className="text-sm text-destructive">{error}</span>}
      <FormChangePassword formSchema={formSchema} onSubmit={onSubmit} />
    </div>
  );
};
