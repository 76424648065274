import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Separator } from "../resources";
import { useAppSelector } from "@/lib/hooks";

const Layout = () => {
  const userId = useAppSelector((state) => state.user.id);

  if (!userId) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <main>
      <Sidebar />
      <section className="ml-[235px] px-8 py-12">
        <Separator className="bg-black mb-5" />
        <Outlet />
      </section>
    </main>
  );
};

export default Layout;
