import CharacterCount from "@tiptap/extension-character-count";
import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { FC, SVGProps } from "react";
import ImageResize from "tiptap-extension-resize-image";

import { LINE_HEIGHT_DEFAULT } from "../constants";
import { BackgroundColor } from "./core/background-color";
import { FontSize } from "./core/font-size";
import { FontWeight } from "./core/font-weight";
import { LineHeight } from "./core/line-height";
import { Tab } from "./core/tab";

export interface ToolbarButton {
  key: string;
  label: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  active?: boolean;
  disable?: boolean;
  // eslint-disable-next-line no-unused-vars
  action?: (value?: number | string) => void;
  value?: string | number;
  subToolbarButtons?: ToolbarButton[];
}

export const extensions = [
  Color.configure({
    types: [TextStyle.name, ListItem.name],
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  StarterKit,
  Highlight,
  Underline,
  Typography,
  Link.configure({
    openOnClick: false,
  }),
  Subscript,
  Superscript,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  ImageResize.configure({
    inline: true,
  }),
  FontSize,
  BackgroundColor,
  CharacterCount,
  LineHeight.configure({
    defaultHeight: LINE_HEIGHT_DEFAULT,
  }),
  FontWeight,
  FontFamily,
  Tab,
];
