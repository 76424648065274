import { Page } from "@/components/common";
import {
  ChangePasswordExcel,
  ChangePasswordUser,
} from "@/components/modules/change-password";
import { useAppSelector } from "@/lib/hooks";

const ChangePassword = () => {
  const { isSuperAdmin } = useAppSelector((state) => state.user);

  return (
    <Page title="계정 관리">
      <div className="space-y-10">
        <ChangePasswordUser />
        {isSuperAdmin && <ChangePasswordExcel />}
      </div>
    </Page>
  );
};

export default ChangePassword;
