import { Editor } from "@tiptap/react";
import { Link2Off } from "lucide-react";
import { useEffect, useState } from "react";

import {
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarLink } from "../helper/toolbar";
import { usePreventEditorFocus } from "../hooks";

export const ToolbarLink = ({ editor }: { editor: Editor }) => {
  const [value, setValue] = useState("");
  const { inputRef, setIsFocused } = usePreventEditorFocus(editor);

  const {
    key,
    label,
    value: valueAttr,
    action,
    disable,
    active,
    ...item
  } = toolbarLink(editor);

  useEffect(() => {
    if (valueAttr) {
      setValue(valueAttr.toString());
    } else {
      setValue("");
    }
  }, [valueAttr]);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            key={key}
            type="button"
            variant={active ? "default" : "outline"}
            size={item.icon ? "icon" : "default"}
            disabled={disable}
            className={cn("h-8", item.icon && "w-8")}
          >
            {item.icon ? <item.icon className="h-4 w-4" /> : label}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex min-w-max gap-2 p-2">
          <Input
            ref={inputRef}
            placeholder="Enter url"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="h-8 w-48"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <Button
            type="button"
            variant="outline"
            size="icon"
            disabled={!active}
            className="h-8 w-8"
            onClick={() => action && action("")}
          >
            <Link2Off className="h-4 w-4" />
          </Button>
          <Button className="h-8" onClick={() => action && action(value)}>
            Save
          </Button>
        </PopoverContent>
      </Popover>
    </>
  );
};
