import { Editor } from "@tiptap/react";

import { Button } from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarTextStyle } from "../helper/toolbar";

export const ToolbarTextStyle = ({ editor }: { editor: Editor }) => {
  return (
    <>
      {toolbarTextStyle(editor).map(
        ({ key, active, action, label, disable, ...item }) => (
          <Button
            key={key}
            type="button"
            variant={active ? "default" : "outline"}
            size={item.icon ? "icon" : "default"}
            onClick={() => action && action()}
            disabled={disable}
            className={cn("h-8", item.icon && "w-8")}
          >
            {item.icon ? <item.icon className="h-4 w-4" /> : label}
          </Button>
        ),
      )}
    </>
  );
};
