import { Editor } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";

export const usePreventEditorFocus = (editor: Editor | null) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleEditorFocus = (event: Event) => {
      if (isFocused && inputRef.current && event.target !== inputRef.current) {
        inputRef.current.focus();
      }
    };

    if (editor) {
      editor.view.dom.addEventListener("focus", handleEditorFocus);
    }

    return () => {
      if (editor) {
        editor.view.dom.removeEventListener("focus", handleEditorFocus);
      }
    };
  }, [isFocused, editor]);

  return { inputRef, isFocused, setIsFocused };
};
