import { useState } from "react";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
} from "../resources";
import { ButtonPermission } from "./ButtonPermission";
import { UserService } from "@/services";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { changeStatusUnmask } from "@/lib/features/configurationSlice";
import { useToast } from "../resources/use-toast";

export const ButtonUnmask = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const unmask = useAppSelector((state) => state.configuration.unmask);

  const [value, setValue] = useState("고객 문의 처리");
  const [text, setText] = useState("");

  const [error, setError] = useState("");
  const [openDialogUnmask, setOpenDialogUnmask] = useState(false);

  const handleShowMask = async () => {
    setError("");
    if (value === "directInput" && !text.trim()) {
      setError("정보를 입력해 주세요.");
    } else {
      await UserService.unmask({
        reason: value === "directInput" ? text : value,
      });
      setOpenDialogUnmask(false);
      dispatch(changeStatusUnmask(true));
      toast({
        description: "마스크를 성공적으로 해제했습니다.",
      });
    }
  };

  return (
    <>
      <ButtonPermission
        title="개인정보 마스킹 해제"
        action={() => setOpenDialogUnmask(true)}
        disabled={unmask}
        contentDialog={
          <p>Super Admin 계정으로 로그인해야만 마스킹 해제가 가능합니다.</p>
        }
      />
      <Dialog open={openDialogUnmask} onOpenChange={setOpenDialogUnmask}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>마스킹 해제 사유를 선택해주세요.</DialogTitle>
          </DialogHeader>
          <RadioGroup value={value} onValueChange={setValue}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="고객 문의 처리" id="r1" />
              <Label htmlFor="r1">고객 문의 처리</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="고객 유형 분석" id="r2" />
              <Label htmlFor="r2">고객 유형 분석</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="compact" id="r3" />
              <Label htmlFor="r3">상담 유형 분석</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="directInput" id="r3" />
              <Label htmlFor="r3">직접 입력</Label>
            </div>
            {value === "directInput" && (
              <>
                <Input
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="사유를 입력해주세요."
                  className="h-8"
                />
                {error && (
                  <span className="text-destructive text-sm">{error}</span>
                )}
              </>
            )}
          </RadioGroup>
          <DialogFooter className="mt-5">
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                취소
              </Button>
            </DialogClose>
            <Button type="submit" onClick={handleShowMask} variant="submit">
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
