import api from "@/config/api";
import axios from "axios";
import { getEnv } from "../config/env";

const REACT_APP_API_URL = getEnv("REACT_APP_API_URL");

export interface LoginData {
  username: string;
  password: string;
}

export interface AuthResponse {
  token: string;
}

export const AuthService = {
  login: async (data: LoginData): Promise<AuthResponse> => {
    try {
      const response = await axios.post<AuthResponse>(
        `${REACT_APP_API_URL}/login`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  },
  logout: async (): Promise<void> => {
    try {
      await api.post<AuthResponse>("logout");
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  },
};
