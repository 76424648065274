import { BubbleMenu, Editor } from "@tiptap/react";

import { Button, Card } from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarTooltip } from "../helper/toolbar";

export const ToolbarTooltip = ({ editor }: { editor: Editor }) => {
  return (
    <BubbleMenu
      tippyOptions={{
        duration: 100,
        placement: "bottom-start",
        appendTo: document.body,
      }}
      editor={editor}
    >
      <Card className="space-x-1 p-1">
        {toolbarTooltip(editor).map(
          ({ key, active, action, label, disable, ...item }) => (
            <Button
              key={key}
              type="button"
              variant={active ? "default" : "ghost"}
              size={item.icon ? "icon" : "default"}
              onClick={() => action && action()}
              disabled={disable}
              className={cn("h-8", item.icon && "w-8")}
            >
              {item.icon ? <item.icon className="h-4 w-4" /> : label}
            </Button>
          )
        )}
      </Card>
    </BubbleMenu>
  );
};
