import { IndexTable } from "@/components/common";
import { Button } from "@/components/resources";
import { Post, PostsService } from "@/services";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface DataTableProps {
  data: Post[];
  totalRows: number;
  selected: number[];
  setSelected: Dispatch<SetStateAction<number[]>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalPage: number;
  searchText: string;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  fetchPosts: (
    page?: number,
    search?: string,
    from?: Date | undefined,
    to?: Date | undefined
  ) => Promise<void>;
  categoryId: 1 | 2;
}

export const DataTable = ({
  data,
  totalRows,
  selected,
  setSelected,
  currentPage,
  setCurrentPage,
  totalPage,
  searchText,
  fromDate,
  toDate,
  fetchPosts,
  categoryId,
}: DataTableProps) => {
  const navigate = useNavigate();

  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
    fetchPosts(page, searchText, fromDate, toDate);
  };

  const handleChangePostStatus = async (id: number) => {
    await PostsService.updatePostStatus(id);
    fetchPosts(currentPage, searchText, fromDate, toDate);
  };

  const headers = [
    { title: "번호", clsx: "min-w-16" },
    { title: "유형", clsx: "min-w-32" },
    { title: "제목", clsx: "min-w-80" },
    { title: "발행일", clsx: "w-28" },
    { title: "상태", clsx: "w-24" },
    { title: "" },
  ];

  const rows = data.map((item, index) => {
    const { id, category_name, title, release_date, status, is_draft } = item;
    return {
      id: id,
      category_name,
      title,
      release_date,
      situation: is_draft ? (
        <Button disabled>임시</Button>
      ) : status ? (
        <Button onClick={() => handleChangePostStatus(id)}>노출</Button>
      ) : (
        <Button
          variant="destructive"
          onClick={() => handleChangePostStatus(id)}
        >
          숨김
        </Button>
      ),
      action: (
        <Button
          onClick={() =>
            navigate(
              categoryId === 2
                ? `/news/organization/edit/${id}`
                : `/news/edit/${id}`
            )
          }
        >
          수정
        </Button>
      ),
    };
  });
  return (
    <>
      <div className="space-y-2">
        <span>총 {totalRows}건</span>
        <IndexTable
          selected={selected}
          setSelected={setSelected}
          headers={headers}
          rows={rows}
          pagination={{
            current: currentPage,
            total: totalPage,
            onPrev: () =>
              handleChangePage(currentPage > 1 ? currentPage - 1 : 1),
            onNext: () =>
              handleChangePage(
                currentPage < totalPage ? currentPage + 1 : totalPage
              ),
            onPage: handleChangePage,
          }}
        />
      </div>
    </>
  );
};
