import { Editor } from "@tiptap/react";
import { ImageIcon } from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarImage = (editor: Editor): ToolbarButton => ({
  key: "image",
  label: "Image",
  icon: ImageIcon,
  value: editor.getAttributes("image").src || "",
  action: (value) =>
    value && editor.chain().focus().setImage({ src: value?.toString() }).run(),
});
