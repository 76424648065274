import api from "@/config/api";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";

interface User {
  id: number;
  is_superadmin: boolean;
  password_export: string;
  username: string;
  status: true;
  created_at: string;
  updated_at: string;
}

interface CreateUser {
  is_superadmin: boolean;
  username: string;
  password: string;
  password_export?: string;
}

interface UpdatePassword {
  password_new: string;
  password_old: string;
}

interface UpdatePasswordExcel {
  password_export_new: string;
  password_export_old: string;
}

export const UserService = {
  createUser: async (userData: Partial<CreateUser>): Promise<User> => {
    try {
      const response: AxiosResponse<User> = await api.post(`/users`, userData);
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  },

  getUser: async (username: string): Promise<User> => {
    try {
      const response: AxiosResponse<User> = await api.get(`/users/${username}`);
      return response.data;
    } catch (error) {
      console.error("Error getting user:", error);
      throw error;
    }
  },

  updatePassword: async (
    username: string,
    data: Partial<UpdatePassword>
  ): Promise<User> => {
    try {
      const response: AxiosResponse<User> = await api.put(
        `/users/${username}/change_password`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  },

  updatePasswordExcel: async (
    username: string,
    data: Partial<UpdatePasswordExcel>
  ): Promise<User> => {
    try {
      const response: AxiosResponse<User> = await api.put(
        `/users/${username}/change_password_export`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error updating password excel:", error);
      throw error;
    }
  },

  deleteUser: async (username: number): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.delete(
        `/users/${username}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  },
  unmask: async (data: Partial<{ reason: string }>): Promise<User> => {
    try {
      const response: AxiosResponse<User> = await api.post(
        `/users/unmasking`,
        data
      );
      Cookies.set("unmask", "true");
      return response.data;
    } catch (error) {
      console.error("Error unmask:", error);
      throw error;
    }
  },
};
