import { Button, Textarea } from "@/components/resources";
import { formatDateTime } from "@/helper/format";
import { useEffect, useRef, useState } from "react";

interface ReplyQuestionProps {
  id: number;
  status: boolean;
  message: string;
  reply: string;
  updatedAt: Date | null;
  onSave?: (value: string) => void;
}

export const ReplyQuestion = ({
  id,
  status,
  message,
  reply,
  updatedAt,
  onSave,
}: ReplyQuestionProps) => {
  const [isReply, setIsReply] = useState(status);
  const [text, setText] = useState(reply);
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setIsEdit(false);
    setIsReply(status);
    setText(reply);
  }, [id]);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  return (
    <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-16 items-start">
      <div className="space-y-3 mt-4">
        <h5 className="font-bold">문의 내용 상세</h5>
        <div className="border border-input h-[192px] overflow-y-scroll px-3 py-2">
          {message}
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex justify-between items-end">
          <h5 className="font-bold">처리 내역</h5>
          <div className="flex flex-row gap-3">
            <Button
              onClick={() => {
                if (text) {
                  onSave && text && onSave(text);
                  setIsEdit(false);
                }
              }}
              disabled={!isEdit || !isReply}
            >
              저장
            </Button>
            <Button
              onClick={() => setIsEdit(true)}
              disabled={isEdit || !isReply}
            >
              수정
            </Button>
          </div>
        </div>
        <div>
          {isReply || status ? (
            <>
              <Textarea
                ref={inputRef}
                disabled={!isEdit}
                value={text}
                className="resize-none h-[192px] rounded-none text-md disabled:opacity-100"
                onChange={(e) => setText(e.target.value)}
              />
              {updatedAt && (
                <span className="text-right block mt-2 text-sm">
                  최종 수정 일시: {formatDateTime(updatedAt)}
                </span>
              )}
            </>
          ) : (
            <div className="w-full flex flex-col gap-y-4 h-[192px] border items-center justify-center">
              <p>아직 작성된 내역이 없습니다.</p>
              <Button
                onClick={() => {
                  if (id) {
                    setIsReply(true);
                    setIsEdit(true);
                  }
                }}
              >
                처리 내역 작성
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
