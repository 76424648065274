import { Editor } from "@tiptap/react";

import { FONT_WEIGHTS, FONT_WEIGHTS_DEFAULT } from "../../constants";
import { ToolbarButton } from "../extensions";

export const toolbarFontWeight = (editor: Editor): ToolbarButton[] => [
  ...FONT_WEIGHTS.map((fontWeight) => ({
    key: `fontWeight-${fontWeight}`,
    label: fontWeight,
    active:
      fontWeight !== FONT_WEIGHTS_DEFAULT
        ? editor.isActive("textStyle", { fontWeight })
        : !FONT_WEIGHTS.some((fw) =>
            fw !== FONT_WEIGHTS_DEFAULT
              ? editor.isActive("textStyle", { fontWeight: fw })
              : false
          ),
    action: () =>
      fontWeight !== FONT_WEIGHTS_DEFAULT
        ? editor.chain().focus().setFontWeight(fontWeight).run()
        : editor.chain().focus().unsetFontWeight().run(),
  })),
];
