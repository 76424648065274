import { Editor } from "@tiptap/react";
import {
  Bold,
  CodeXml,
  Italic,
  List,
  ListOrdered,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarTextStyle = (editor: Editor): ToolbarButton[] => [
  {
    key: "bold",
    label: "Bold",
    icon: Bold,
    active: editor.isActive("bold"),
    disable: !editor.can().chain().focus().toggleBold().run(),
    action: () => editor.chain().focus().toggleBold().run(),
  },
  {
    key: "italic",
    label: "Italic",
    icon: Italic,
    active: editor.isActive("italic"),
    disable: !editor.can().chain().focus().toggleItalic().run(),
    action: () => editor.chain().focus().toggleItalic().run(),
  },
  {
    key: "underline",
    label: "Underline",
    icon: Underline,
    active: editor.isActive("underline"),
    disable: !editor.can().chain().focus().toggleUnderline().run(),
    action: () => editor.chain().focus().toggleUnderline().run(),
  },
  {
    key: "strike",
    label: "Strike",
    icon: Strikethrough,
    active: editor.isActive("strike"),
    disable: !editor.can().chain().focus().toggleStrike().run(),
    action: () => editor.chain().focus().toggleStrike().run(),
  },
  {
    key: "code",
    label: "Code",
    icon: CodeXml,
    active: editor.isActive("code"),
    disable: !editor.can().chain().focus().toggleCode().run(),
    action: () => editor.chain().focus().toggleCode().run(),
  },
  {
    key: "subscript",
    label: "Subscript",
    icon: Subscript,
    active: editor.isActive("subscript"),
    disable: !editor.can().chain().focus().toggleSubscript().run(),
    action: () => editor.chain().focus().toggleSubscript().run(),
  },
  {
    key: "superscript",
    label: "Superscript",
    icon: Superscript,
    active: editor.isActive("superscript"),
    disable: !editor.can().chain().focus().toggleSuperscript().run(),
    action: () => editor.chain().focus().toggleSuperscript().run(),
  },
  {
    key: "bulletList",
    label: "Bullet list",
    icon: List,
    active: editor.isActive("bulletList"),
    action: () => editor.chain().focus().toggleBulletList().run(),
  },
  {
    key: "orderedList",
    label: "Ordered list",
    icon: ListOrdered,
    active: editor.isActive("orderedList"),
    action: () => editor.chain().focus().toggleOrderedList().run(),
  },
];
