import {
  ContactUs,
  ContactUsDetail,
  ContactUsOrderBy,
  ContactUsService,
} from "@/services";
import { useEffect, useState } from "react";
import { ReplyQuestion } from "@/components/modules/customers";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  Input,
} from "@/components/resources";
import { List } from "lucide-react";
import {
  ButtonPermission,
  ButtonUnmask,
  DialogDelete,
  IndexTable,
} from "@/components/common";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "@/helper/format";
import { useAppSelector } from "@/lib/hooks";
import { PER_PAGE } from "@/constants";

const headers = [
  { title: "번호", clsx: "min-w-16" },
  { title: "처리 현황", clsx: "min-w-24" },
  { title: "문의 유형", clsx: "min-w-48" },
  { title: "이름", clsx: "min-w-32" },
  { title: "회사", clsx: "min-w-24" },
  { title: "산업군", clsx: "min-w-48" },
  { title: "부서", clsx: "min-w-24" },
  { title: "직함", clsx: "min-w-48" },
  { title: "전화번호", clsx: "min-w-48" },
  { title: "이메일", clsx: "min-w-48" },
  { title: "관심 제품 및 솔루션(상세)", clsx: "min-w-48" },
  { title: "문의 일자(개인정보 수집/이용 동의일)", clsx: "min-w-64" },
  { title: "개인정보 삭제 예정일", clsx: "min-w-48" },
];

const sortItems = [
  { value: "createdAt", label: "등록일순" },
  { value: "name", label: "이름순" },
  { value: "company", label: "회사명순" },
  { value: "inquiryType", label: "문의 유형순" },
];

export const ContactUsManagement = ({
  customerId,
}: {
  customerId?: number;
}) => {
  const navigate = useNavigate();

  const unmask = useAppSelector((state) => state.configuration.unmask);

  const [data, setData] = useState<ContactUs[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [selected, setSelected] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [orderBy, setOrderBy] = useState<ContactUsOrderBy>("createdAt");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [contactUs, setContactUs] = useState<ContactUsDetail>();

  const fetchListContactUs = async (
    page?: number,
    search?: string,
    orderBy?: ContactUsOrderBy
  ) => {
    const contactUsRes = await ContactUsService.getListContactUs(
      page ?? 1,
      PER_PAGE,
      search,
      orderBy,
      customerId
    );
    setData(contactUsRes.list || []);
    setTotalPage(Math.ceil(contactUsRes.totalRows / PER_PAGE));
    setTotalRows(contactUsRes.totalRows);
  };

  useEffect(() => {
    fetchListContactUs();
  }, [unmask]);

  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
    fetchListContactUs(page, searchText, orderBy);
  };
  const handleSearch = async () => {
    setCurrentPage(1);
    fetchListContactUs(1, searchText, orderBy);
  };

  const handleSort = async (orderBy: ContactUsOrderBy) => {
    setCurrentPage(1);
    fetchListContactUs(1, searchText, orderBy);
  };

  const handleDeleteMultilple = async () => {
    await ContactUsService.deleteMultipleContactUs(selected);
    setCurrentPage(1);
    setSelected([]);
    fetchListContactUs(1, searchText, orderBy);
    setOpenDialogDelete(false);
    if (contactUs?.id && selected.includes(contactUs?.id)) {
      setContactUs(undefined);
    }
  };

  const fetchContactUsDetail = async (id: number) => {
    if (!(contactUs?.id === id)) {
      const contactUsDetailRes = await ContactUsService.getContactUsDetail(id);
      setContactUs(contactUsDetailRes);
    }
  };

  const handleUpdateContactUs = async (value: string) => {
    if (contactUs?.id) {
      const contactUsUpdated = await ContactUsService.updateContactUs(
        contactUs.id,
        {
          processing_history: value,
        }
      );
      setCurrentPage(1);
      setSelected([]);
      fetchListContactUs(1, searchText, orderBy);
      setContactUs(contactUsUpdated);
    }
  };

  const handleExportToExcel = async () => {
    await ContactUsService.export(customerId);
  };

  const rows = data.map((item, index) => {
    const {
      id,
      name,
      processing_status,
      inquiry_type,
      company,
      industry,
      department,
      title,
      phone_number,
      email,
      solutions,
      created_at,
      scheduled_delete_at,
    } = item;
    return {
      id,
      processingStatus: !processing_status ? (
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 bg-destructive rounded-full" />
          <span>대기</span>
        </div>
      ) : (
        <div className="flex gap-x-2 items-center">
          <div className="w-3 h-3 bg-primary rounded-full" />
          <span>완료</span>
        </div>
      ),
      inquiry_type,
      name,
      company,
      industry,
      department,
      title,
      phone_number,
      email: (
        <span
          onClick={() => {
            const contactUs = data.find((item) => item.id === id);
            if (contactUs && contactUs.customer_id) {
              navigate(`/customers/${contactUs?.customer_id}`);
            }
          }}
        >
          {email}
        </span>
      ),
      solutions,
      createdAt: formatDateTime(new Date(created_at)),
      scheduledDeleteAt: formatDateTime(new Date(scheduled_delete_at)),
    };
  });

  return (
    <>
      <div className="space-y-3">
        <div className="flex gap-3 justify-between items-end flex-wrap">
          <h3 className="font-bold text-[28px]">고객 문의</h3>
          <div className="flex gap-x-10 gap-y-4 flex-wrap">
            <div className="flex gap-5 items-center flex-wrap">
              <span>총 {totalRows} 건</span>
              {!customerId && (
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex items-center gap-1 focus:outline-none">
                    <List className="w-5 h-5" />
                    <span>
                      {sortItems.find((item) => item.value === orderBy)?.label}
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-36" align="start">
                    <DropdownMenuRadioGroup
                      value={orderBy}
                      onValueChange={(value) => {
                        setOrderBy(value as ContactUsOrderBy);
                        handleSort(value as ContactUsOrderBy);
                      }}
                    >
                      {sortItems.map((item, key) => (
                        <DropdownMenuRadioItem key={key} value={item.value}>
                          <span>{item.label}</span>
                        </DropdownMenuRadioItem>
                      ))}
                    </DropdownMenuRadioGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>

            <div className="flex gap-3">
              <Input
                placeholder="회사명, 문의내용으로 검색"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Button onClick={handleSearch}>검색</Button>
            </div>
            <div className="flex flex-wrap gap-3">
              <ButtonPermission
                title="엑셀 다운로드"
                action={handleExportToExcel}
                contentDialog={
                  <p>
                    Super Admin 계정으로 로그인해야만 다운로드가 가능합니다.
                  </p>
                }
              />
              {!customerId && <ButtonUnmask />}

              <Button
                disabled={selected.length === 0}
                onClick={() => setOpenDialogDelete(true)}
              >
                고객정보 삭제
              </Button>
            </div>
          </div>
        </div>
        <IndexTable
          selected={selected}
          setSelected={setSelected}
          headers={headers}
          rows={rows}
          onClickRow={(id) => fetchContactUsDetail(id)}
          onDoubleClickRow={
            !customerId
              ? (id) => {
                  const contactUs = data.find((item) => item.id === id);
                  if (contactUs && contactUs.customer_id) {
                    navigate(`/customers/${contactUs?.customer_id}`);
                  }
                }
              : undefined
          }
          pagination={{
            current: currentPage,
            total: totalPage,
            onPrev: () =>
              handleChangePage(currentPage > 1 ? currentPage - 1 : 1),
            onNext: () =>
              handleChangePage(
                currentPage < totalPage ? currentPage + 1 : totalPage
              ),
            onPage: handleChangePage,
          }}
        />
      </div>

      <div className="mt-10">
        <ReplyQuestion
          id={Number(contactUs?.id)}
          status={!!contactUs?.processing_updated_at}
          message={contactUs?.details || ""}
          reply={contactUs?.processing_history || ""}
          onSave={handleUpdateContactUs}
          updatedAt={
            contactUs?.processing_updated_at
              ? new Date(contactUs?.processing_updated_at)
              : null
          }
        />
      </div>

      <DialogDelete
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        onSubmit={handleDeleteMultilple}
      />
    </>
  );
};
