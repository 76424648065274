import { Editor } from "@tiptap/react";
import { ChevronDown } from "lucide-react";

import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarFontFamily } from "../helper/toolbar";

export const ToolbarFontFamily = ({ editor }: { editor: Editor }) => {
  const { label } =
    toolbarFontFamily(editor).find((item) => item.active) ??
    toolbarFontFamily(editor)[0];

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          type="button"
          className="h-8 w-44 justify-between"
        >
          {label}
          <ChevronDown className="ml-1 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-44 p-0">
        <Command>
          <CommandInput placeholder="Enter font family" />
          <CommandList className="px-2 py-1">
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {toolbarFontFamily(editor).map(
                ({ key, active, action, label, disable }) => (
                  <DropdownMenuItem
                    key={key}
                    className={cn(
                      "py-2",
                      active &&
                        "bg-primary text-primary-foreground focus:bg-primary focus:text-primary-foreground"
                    )}
                    disabled={disable}
                    onClick={() => {
                      action && action();
                    }}
                  >
                    <CommandItem className="w-full p-0 aria-selected:bg-inherit aria-selected:text-inherit">
                      {label}
                    </CommandItem>
                  </DropdownMenuItem>
                )
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
