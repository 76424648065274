import { Expand, Shrink } from "lucide-react";
import { useEffect, useRef } from "react";

import { Button } from "@/components/resources";

import { useResizeObserver } from "../hooks";

export const ToolbarScreen = () => {
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    elementRef.current = document.getElementById("text-editor--toolbar");
  }, []);

  const { height = 0 } = useResizeObserver({
    ref: elementRef,
    box: "border-box",
  });

  useEffect(() => {
    const textEditorInputElement =
      document.getElementById("text-editor--input");

    if (textEditorInputElement) {
      if (document.fullscreenElement) {
        textEditorInputElement.style.height = `calc(100vh - ${height}px - 4rem)`;
      } else {
        textEditorInputElement.style.height = "";
      }
    }
  }, [height]);

  const toggleFullscreen = (): void => {
    const textEditorElement = document.getElementById("text-editor");
    const textEditorInputElement =
      document.getElementById("text-editor--input");

    if (textEditorElement && textEditorInputElement) {
      if (!document.fullscreenElement) {
        textEditorElement.requestFullscreen();
        textEditorElement.classList.add("p-4");
        textEditorInputElement.style.height = `calc(100vh - ${height}px - 4rem)`;
      } else {
        document.exitFullscreen();
        textEditorElement.classList.remove("p-4");
        textEditorInputElement.style.height = "";
      }
    }
  };

  return (
    <Button
      type="button"
      variant="outline"
      size="icon"
      className="h-8 w-8"
      onClick={toggleFullscreen}
    >
      {!!document.fullscreenElement ? (
        <Shrink className="h-4 w-4" />
      ) : (
        <Expand className="h-4 w-4" />
      )}
    </Button>
  );
};
