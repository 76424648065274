import { Editor } from "@tiptap/react";
import { Plus } from "lucide-react";

import { ToolbarButton } from "../extensions";

const headingFontSizes: { [key: number]: string } = {
  1: "32px",
  2: "24px",
  3: "20px",
  4: "16px",
  5: "14px",
  6: "12px",
};

const isValidFontSize = (value: string): boolean => {
  return /^(\d*\.?\d+)px$/.test(value);
};

export const toolbarFontSize = (editor: Editor): ToolbarButton => ({
  key: "fontSize",
  label: "Font size",
  icon: Plus,
  value: (() => {
    const { fontSize } = editor.getAttributes("textStyle");
    if (fontSize && isValidFontSize(fontSize)) return fontSize;
    for (let level = 1; level <= 6; level++) {
      if (editor.isActive("heading", { level })) {
        return headingFontSizes[level];
      }
    }
    return "";
  })(),
  action: (value) => {
    const regex = /^\d*\.?\d*$/;
    if (value && regex.test(String(value))) {
      editor.chain().focus().setFontSize(`${value}px`).run();
    } else {
      editor.chain().focus().unsetFontSize().run();
    }
  },
});
