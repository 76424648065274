import api from "@/config/api";
import { AxiosResponse } from "axios";

export interface CustomerStatistics {
  email: string;
  is_consent_marketing: boolean;
  contact_us_count: number;
  contact_us_unprocessed_count: number;
  download_count: number;
  request_demo_count: number;
}

export const CustomerService = {
  getCustomerStatistics: async (
    customerId: number
  ): Promise<CustomerStatistics> => {
    try {
      const response: AxiosResponse<CustomerStatistics> = await api.get(
        `/customer/${customerId}/statistics`
      );
      return response.data;
    } catch (error) {
      console.error("Error getting list customer consent marketing:", error);
      throw error;
    }
  },
};
