import { Extension } from "@tiptap/core";
import { TextStyle } from "@tiptap/extension-text-style";

export interface FontWeightOptions {
  types: string[];
  weights: string[];
  defaultWeight: string;
}

declare module "@tiptap/core" {
  // eslint-disable-next-line no-unused-vars
  interface Commands<ReturnType> {
    fontWeight: {
      /**
       * Set the font weight attribute
       */
      // eslint-disable-next-line no-unused-vars
      setFontWeight: (weight: string) => ReturnType;
      /**
       * Unset the font weight attribute
       */
      unsetFontWeight: () => ReturnType;
    };
  }
}

export const FontWeight = Extension.create<FontWeightOptions>({
  name: "fontWeight",

  addOptions() {
    return {
      types: ["textStyle"],
      weights: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
      defaultWeight: "400",
    };
  },

  addExtensions() {
    return [TextStyle];
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontWeight: {
            default: this.options.defaultWeight,
            parseHTML: (element) =>
              element.style.fontWeight || this.options.defaultWeight,
            renderHTML: (attributes) => {
              if (attributes.fontWeight === this.options.defaultWeight) {
                return {};
              }

              return { style: `font-weight: ${attributes.fontWeight}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontWeight:
        (weight: string) =>
        ({ chain }) => {
          if (!this.options.weights.includes(weight)) {
            return false;
          }

          return chain().setMark("textStyle", { fontWeight: weight }).run();
        },

      unsetFontWeight:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontWeight: undefined })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
