import { Palette } from "lucide-react";
import { Input, InputProps } from "../resources";
import React from "react";
import { ColorPicker } from "./ColorPicker";

interface InputColorProps extends InputProps {
  handleChange?: (value: string) => void;
}

const InputColor = React.forwardRef<HTMLInputElement, InputColorProps>(
  ({ value, handleChange, ...props }, ref) => {
    return (
      <div className="relative">
        <Input
          ref={ref}
          value={value}
          placeholder="#000000"
          {...props}
          className="pr-10"
        />

        <ColorPicker
          value={value}
          handleChange={(value) =>
            handleChange && handleChange(value === "initial" ? "" : value)
          }
        >
          <button
            type="button"
            className="absolute top-1 right-1 bg-background h-8 w-8 flex justify-center items-center"
            style={{ color: value?.toString() ?? "#000" }}
          >
            <Palette className="h-6 w-6" />
          </button>
        </ColorPicker>
      </div>
    );
  }
);

InputColor.displayName = "InputColor";

export { InputColor };
