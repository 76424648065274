import { Editor } from "@tiptap/react";
import {
  Code,
  Divide,
  Highlighter,
  Quote,
  Redo,
  RemoveFormatting,
  Undo,
} from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarButtons = (editor: Editor): ToolbarButton[] => [
  {
    key: "horizontalRule",
    label: "Horizontal rule",
    icon: Divide,
    action: () => editor.chain().focus().setHorizontalRule().run(),
  },
  {
    key: "codeBlock",
    label: "Code block",
    icon: Code,
    active: editor.isActive("codeBlock"),
    action: () => editor.chain().focus().toggleCodeBlock().run(),
  },
  {
    key: "blockquote",
    label: "Blockquote",
    icon: Quote,
    active: editor.isActive("blockquote"),
    action: () => editor.chain().focus().toggleBlockquote().run(),
  },
  {
    key: "highlight",
    label: "Highlight",
    icon: Highlighter,
    active: editor.isActive("highlight"),
    action: () => editor.chain().focus().toggleHighlight().run(),
  },
  {
    key: "clearMarks",
    label: "Clear marks",
    icon: RemoveFormatting,
    action: () => editor.chain().focus().unsetAllMarks().run(),
  },
  {
    key: "undo",
    label: "Undo",
    icon: Undo,
    disable: !editor.can().chain().focus().undo().run(),
    action: () => editor.chain().focus().undo().run(),
  },
  {
    key: "redo",
    label: "Redo",
    icon: Redo,
    disable: !editor.can().chain().focus().redo().run(),
    action: () => editor.chain().focus().redo().run(),
  },
];
