import { CircleUserRound, LogOut, User } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../resources";
import Navigation from "./Navigation";
import { AuthService } from "@/services";
import { useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/helper/utils";
import { useAuth } from "@/contexts/AuthContext";

const Sidebar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogout = async () => {
    await AuthService.logout();
    logout();
    navigate("/sign-in");
  };

  return (
    <div className="fixed z-40 top-0 left-0 w-full max-w-[235px] bg-black text-white min-h-[100vh] p-[18px] divide-y-[1px] divide-white">
      <div>
        <img src="/assets/images/logo.png" alt="Logo" width={198} height={31} />
        <h5 className="text-[15px] font-bold text-center mt-3">
          Website Admin System
        </h5>

        <DropdownMenu>
          <DropdownMenuTrigger className="flex items-center gap-1 focus:outline-none">
            <div className="flex items-center gap-2 m-3">
              <CircleUserRound
                className={cn(
                  "w-6 h-6",
                  pathname === "/change-password" && "text-[#FFB800]"
                )}
              />
              <span
                className={cn(
                  pathname === "/change-password" && "text-[#FFB800]"
                )}
              >
                계정 관리
              </span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-48" align="start">
            <DropdownMenuItem onClick={() => navigate("/change-password")}>
              <User className="mr-2 h-4 w-4" />
              <span>Change password</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="py-6">
        <Navigation />
      </div>
    </div>
  );
};

export default Sidebar;
