import {
  ContactUsManagement,
  ConsentMarketingManagement,
  DownloadManager,
} from "@/components/modules/customers";
import { Separator } from "@/components/resources";

const CustomerManagement = () => {
  return (
    <>
      <ContactUsManagement />
      <div className="my-10">
        <Separator />
      </div>
      <DownloadManager />
      <div className="my-10">
        <Separator />
      </div>
      <ConsentMarketingManagement />
    </>
  );
};

export default CustomerManagement;
