import { Dispatch, SetStateAction } from "react";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
} from "../resources";

interface DialogDeleteProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => Promise<void>;
}
export const DialogDelete = ({
  open = false,
  setOpen,
  onSubmit,
}: DialogDeleteProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <div>
          <p>선택한 고객 정보를 삭제 하시겠습니까?</p>
        </div>
        <DialogFooter className="mt-5">
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              취소
            </Button>
          </DialogClose>

          <Button type="submit" onClick={onSubmit} variant="submit">
            확인
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
