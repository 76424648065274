import { Editor } from "@tiptap/react";
import { ChevronDown, Table } from "lucide-react";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarTable } from "../helper/toolbar";

export const ToolbarTable = ({ editor }: { editor: Editor }) => {
  const active = !!toolbarTable(editor).find((item) => item.active) || false;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          type="button"
          variant={active ? "default" : "outline"}
          size="icon"
          className="h-8 w-14"
        >
          <Table className="h-4 w-4" />
          <ChevronDown className="ml-1 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-44 px-1 py-1">
        {toolbarTable(editor).map(
          ({
            key,
            active,
            action,
            label,
            disable,
            subToolbarButtons,
            ...item
          }) =>
            !subToolbarButtons ? (
              <DropdownMenuItem
                key={key}
                className={cn(
                  "py-2",
                  active &&
                    "bg-primary text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                )}
                disabled={disable}
                onClick={() => {
                  action && action();
                }}
              >
                {item.icon && <item.icon className="mr-1 h-4 w-4" />}
                {label}
              </DropdownMenuItem>
            ) : (
              <DropdownMenuSub key={key}>
                <DropdownMenuSubTrigger>
                  {item.icon && <item.icon className="mr-1 h-4 w-4" />}
                  {label}
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent className="min-w-min">
                    {subToolbarButtons.map((subItem) => (
                      <DropdownMenuItem
                        key={subItem.key}
                        className={cn(
                          "py-2",
                          subItem.active &&
                            "bg-primary text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                        )}
                        disabled={subItem.disable}
                        onClick={() => {
                          subItem.action && subItem.action();
                        }}
                      >
                        {subItem.icon && (
                          <subItem.icon className="mr-1 h-4 w-4" />
                        )}
                        {subItem.label}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
