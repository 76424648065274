import { Editor } from "@tiptap/react";
import { Minus, Plus } from "lucide-react";
import { useEffect } from "react";

import { Button, Input } from "@/components/resources";

import { FONT_SIZE_DEFAULT } from "../constants";
import { toolbarFontSize } from "../helper/toolbar";
import { usePreventEditorFocus } from "../hooks";

export const ToolbarFontSize = ({ editor }: { editor: Editor }) => {
  const { inputRef, setIsFocused } = usePreventEditorFocus(editor);
  const { value: valueAttr, action, disable } = toolbarFontSize(editor);

  useEffect(() => {
    inputRef.current &&
      (inputRef.current.value =
        String(valueAttr).replace("px", "") || String(FONT_SIZE_DEFAULT));
  }, [valueAttr, inputRef]);

  const handleChangeFontSize = (value: string) => {
    action && action(value !== String(FONT_SIZE_DEFAULT) ? value : "");
  };

  const handleFontSizeChange = (adjustment: number) => {
    const regex = /^\d*\.?\d*$/;
    const currentValue = inputRef.current!.value;
    if (regex.test(currentValue)) {
      const newValue = String(
        Math.max(
          parseFloat(currentValue || String(FONT_SIZE_DEFAULT)) + adjustment,
          1
        )
      );
      inputRef.current!.value = newValue;
      handleChangeFontSize(newValue);
    } else {
      handleChangeFontSize("1");
    }
  };

  const handlePlusFontSize = () => handleFontSizeChange(1);
  const handleMinusFontSize = () => handleFontSizeChange(-1);

  if (!editor) {
    return null;
  }

  return (
    <div className="px-1/2 flex items-center gap-x-1">
      <Button
        type="button"
        variant="link"
        size="icon"
        className="h-4 w-4 p-0"
        disabled={disable}
      >
        <Minus className="h-4 w-4" onClick={handleMinusFontSize} />
      </Button>
      <Input
        ref={inputRef}
        defaultValue={FONT_SIZE_DEFAULT}
        className="h-8 w-14 text-center"
        disabled={disable}
        onChange={(e) => {
          if (inputRef.current) {
            inputRef.current.focus();
            handleChangeFontSize(String(e.target.value));
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Button
        type="button"
        variant="link"
        size="icon"
        className="h-4 w-4 p-0"
        disabled={disable}
      >
        <Plus className="h-4 w-4" onClick={handlePlusFontSize} />
      </Button>
    </div>
  );
};
