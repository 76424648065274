import { CustomerStatistics } from "@/services";

const DashboardCustomer = ({
  customerStatistics,
}: {
  customerStatistics?: CustomerStatistics;
}) => {
  const items = [
    { label: "상담 문의", value: customerStatistics?.contact_us_count },
    {
      label: "미처리 상담",
      value: customerStatistics?.contact_us_unprocessed_count,
    },
    { label: "데모 요청", value: customerStatistics?.request_demo_count },
    { label: "콘텐츠 다운로드", value: customerStatistics?.download_count },
    {
      label: "마케팅 수신동의",
      value: customerStatistics?.is_consent_marketing ? "Y" : " N",
    },
  ];

  return (
    <div className="border px-8 py-6 h-full flex gap-x-10 flex-wrap">
      <div className="col-span-1">
        <h4 className="font-bold text-xl text-primary">대쉬보드</h4>
        <div className="flex flex-wrap gap-x-8 mt-6">
          <span className="font-semibold text-primary">이메일</span>
          <span>{customerStatistics?.email || ""}</span>
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex gap-4 justify-center mt-6 flex-wrap">
          {items.map((item, key) => (
            <div
              key={key}
              className="border border-primary h-[160px] w-[160px] flex flex-col items-center justify-center gap-8 p-4"
            >
              <strong className="text-center">{item.label}</strong>
              <strong className="text-4xl text-primary">{item.value}</strong>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardCustomer;
