import Layout from "@/components/layouts/Layout";
import Home from "@/pages";
import CustomerInformation from "@/pages/customers/information";
import CustomerManagement from "@/pages/customers/management";
import News from "@/pages/news";
import NewCreate from "@/pages/news/create";
import NewsOrganization from "@/pages/news/organization";
import NewsOrganizationCreate from "@/pages/news/organization/create";
import ChangePassword from "@/pages/profile/change-password";
import SignIn from "@/pages/sign-in";
import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/news/organization",
        element: <NewsOrganization />,
      },
      {
        path: "/news/create",
        element: <NewCreate />,
      },
      {
        path: "/news/organization/create",
        element: <NewsOrganizationCreate />,
      },
      {
        path: "/news/edit/:id",
        element: <NewCreate />,
      },
      {
        path: "/news/organization/edit/:id",
        element: <NewsOrganizationCreate />,
      },
      {
        path: "/customers",
        element: <CustomerManagement />,
      },
      {
        path: "/customers/:id",
        element: <CustomerInformation />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
    ],
  },
  { path: "/sign-in", element: <SignIn /> },
]);
