import { parseToken } from "@/helper/utils";
import { updateUser } from "@/lib/features/userSlice";
import { useAppDispatch } from "@/lib/hooks";
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import Cookies from "js-cookie";
import { changeStatusUnmask } from "@/lib/features/configurationSlice";

interface AuthContextType {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();

  const [token, setToken] = useState<string | null>(
    Cookies.get("token") || null
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      try {
        const decoded = parseToken(token);
        const { user_id: id, username, is_superadmin: isSuperAdmin } = decoded;
        dispatch(updateUser({ id, username, isSuperAdmin }));

        const unmask = Cookies.get("unmask");
        if (unmask && unmask === "true") {
          dispatch(changeStatusUnmask(true));
        }
      } catch (error) {
        console.error("Failed to parse token:", error);
      }
    } else {
      Cookies.remove("unmask");
      dispatch(changeStatusUnmask(false));
    }
    setLoading(false);
  }, [token, dispatch]);

  const login = (token: string) => {
    setToken(token);
    Cookies.set("token", token);
  };

  const logout = () => {
    setToken(null);
    Cookies.remove("token");
    Cookies.remove("unmask");
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
