import { Editor } from "@tiptap/react";

import { LINE_HEIGHTS, LINE_HEIGHT_DEFAULT } from "../../constants";
import { ToolbarButton } from "../extensions";

export const toolbarLineHeight = (editor: Editor): ToolbarButton[] => [
  ...LINE_HEIGHTS.map((lineHeight) => ({
    key: `lineHeight-${lineHeight}`,
    label: lineHeight,
    active:
      lineHeight !== LINE_HEIGHT_DEFAULT
        ? editor.isActive("heading", { lineHeight }) ||
          editor.isActive("paragraph", { lineHeight })
        : !LINE_HEIGHTS.some((lh) =>
            lh !== LINE_HEIGHT_DEFAULT
              ? editor.isActive("heading", { lineHeight: lh }) ||
                editor.isActive("paragraph", { lineHeight: lh })
              : false
          ),
    action: () =>
      lineHeight !== LINE_HEIGHT_DEFAULT
        ? editor.chain().focus().setLineHeight(lineHeight).run()
        : editor.chain().focus().unsetLineHeight().run(),
  })),
];
