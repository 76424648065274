import { AuthSignIn } from "@/components/modules/auth";
import { useAuth } from "@/contexts/AuthContext";
import { useEffect } from "react";

const SignIn = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);
  return (
    <div className="flex justify-center items-center h-[100vh] w-full">
      <AuthSignIn />
    </div>
  );
};

export default SignIn;
