import { Editor } from "@tiptap/react";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Pilcrow,
} from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarHeading = (editor: Editor): ToolbarButton[] => [
  {
    key: "paragraph",
    label: "Paragraph",
    icon: Pilcrow,
    active: editor.isActive("paragraph"),
    action: () => editor.chain().focus().setParagraph().run(),
  },
  ...[1, 2, 3, 4, 5, 6].map((level) => ({
    key: `heading${level}`,
    label: `Heading ${level}`,
    icon:
      level === 1
        ? Heading1
        : level === 2
          ? Heading2
          : level === 3
            ? Heading3
            : level === 4
              ? Heading4
              : level === 5
                ? Heading5
                : level === 6
                  ? Heading6
                  : Pilcrow,
    active: editor.isActive("heading", { level }),
    action: () =>
      editor
        .chain()
        .focus()
        .toggleHeading({ level } as { level: 1 | 2 | 3 | 4 | 5 | 6 })
        .run(),
  })),
];
