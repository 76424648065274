import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ConfigurationState {
  unmask: boolean;
}

const initialState: ConfigurationState = {
  unmask: false,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    changeStatusUnmask: (state, action: PayloadAction<boolean>) => {
      state.unmask = action.payload;
    },
  },
});

export const { changeStatusUnmask } = configurationSlice.actions;
export default configurationSlice.reducer;
