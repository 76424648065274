import { Editor } from "@tiptap/react";

import { ToolbarButton } from "../extensions";
import { toolbarTextStyle } from "./toolbarTextStyle";

export const toolbarTooltip = (editor: Editor): ToolbarButton[] => [
  ...toolbarTextStyle(editor).filter(
    (item) => !["bulletList", "orderedList"].includes(item.key),
  ),
];
