import { Editor } from "@tiptap/react";
import { ChevronDown, Type } from "lucide-react";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarFontWeight } from "../helper/toolbar";

export const ToolbarFontWeight = ({ editor }: { editor: Editor }) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            type="button"
            variant="outline"
            size="icon"
            className="h-8 w-14"
          >
            <Type className="h-4 w-4" />
            <ChevronDown className="ml-1 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-max px-1 py-1">
          {toolbarFontWeight(editor).map(
            ({ key, active, action, label, disable }) => (
              <DropdownMenuItem
                key={key}
                className={cn(
                  "justify-center py-2",
                  active &&
                    "bg-primary text-primary-foreground focus:bg-primary focus:text-primary-foreground"
                )}
                disabled={disable}
                onClick={() => {
                  action && action();
                }}
              >
                {label}
              </DropdownMenuItem>
            )
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
