import {
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from "@/components/resources";
import { useAuth } from "@/contexts/AuthContext";
import { cn } from "@/helper/utils";
import { AuthService } from "@/services";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const FormSchema = z.object({
  username: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  password: z.string().min(2, {
    message: "Password must be at least 2 characters.",
  }),
});

export const AuthSignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [error, setError] = useState("");

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const { username, password } = data;
    try {
      const { token } = await AuthService.login({ username, password });
      login(token);
      navigate("/");
    } catch (error) {
      setError("Username or password is incorrect.");
    }
  };
  return (
    <Card className="w-[320px] bg-black flex flex-col items-center justify-center py-5 px-8">
      <div>
        <img src="/assets/images/logo.png" alt="Logo" />
      </div>
      <span className="text-destructive text-center text-sm mt-3">{error}</span>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 w-full mt-6 flex flex-col"
        >
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="아이디" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="password" placeholder="비밀번호" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mx-auto">
            <Button
              type="submit"
              disabled={!form.formState.isValid}
              className={cn(
                form.formState.isValid && "bg-[#3D4248] hover:bg-[#3D4248]/90"
              )}
            >
              로그인
            </Button>
          </div>
        </form>
      </Form>
    </Card>
  );
};
