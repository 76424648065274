import { RouterProvider } from "react-router-dom";
import "./styles/css/globals.css";
import "./styles/scss/main.scss";

import { router } from "./routes";
import { AuthProvider } from "./contexts/AuthContext";
import { Provider } from "react-redux";
import store from "./lib/store";
import { Toaster } from "./components/resources";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <RouterProvider router={router} />
        <Toaster />
      </AuthProvider>
    </Provider>
  );
}

export default App;
