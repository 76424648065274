import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  id: number | null;
  username: string;
  isSuperAdmin: boolean;
}

const initialState: UserState = {
  id: null,
  username: "",
  isSuperAdmin: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserState>) => {
      const { id, username, isSuperAdmin } = action.payload;
      state.id = id;
      state.username = username;
      state.isSuperAdmin = isSuperAdmin;
    },
  },
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
