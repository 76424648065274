import { InputProps } from "../resources";
import React, { useState } from "react";

interface InputFileProps extends InputProps {
  imageFilePreview?: string;
  onChangeImageFile?: (value: File) => void;
}

const InputFile = React.forwardRef<
  HTMLInputElement,
  InputFileProps & { categoryId: 1 | 2 }
>(({ imageFilePreview, id, onChange, categoryId }, ref) => {
  const [preview, setPreview] = useState(
    imageFilePreview || "/assets/images/empty-file.png"
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPreview(fileURL);
      onChange && onChange(event);
    }
  };

  return (
    <div className="flex gap-4">
      <label htmlFor={id} className="cursor-pointer">
        <img src={preview} alt="File Preview" />
      </label>
      <div>
        <input
          ref={ref}
          type="file"
          id={id}
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
        <p className="mt-3">
          JPG/PNG 형식의 파일 <br />- 사이즈 : 1280px *{" "}
          {categoryId === 2 ? "1280" : "720"}px <br />- 용량 : 2MB 이하
        </p>
      </div>
    </div>
  );
});

InputFile.displayName = "InputFile";

export { InputFile };
