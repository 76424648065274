import { Editor } from "@tiptap/react";
import { ChevronDown } from "lucide-react";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarHeading } from "../helper/toolbar";

export const ToolbarHeading = ({ editor }: { editor: Editor }) => {
  const { label } =
    toolbarHeading(editor).find((item) => item.active) ??
    toolbarHeading(editor)[0];

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          type="button"
          className="h-8 w-36 justify-between"
        >
          {label}
          <ChevronDown className="ml-1 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-36 px-2 py-1">
        {toolbarHeading(editor).map(
          ({ key, active, action, label, disable, ...item }) => (
            <DropdownMenuItem
              key={key}
              className={cn(
                "py-2",
                active &&
                  "bg-primary text-primary-foreground focus:bg-primary focus:text-primary-foreground",
              )}
              disabled={disable}
              onClick={() => {
                action && action();
              }}
            >
              {item.icon && <item.icon className="mr-2 h-5 w-5" />}
              {label}
            </DropdownMenuItem>
          ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
