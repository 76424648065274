import { Editor } from "@tiptap/react";
import { Baseline } from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarColor = (editor: Editor): ToolbarButton => ({
  key: "color",
  label: "Color",
  icon: Baseline,
  active: editor.isActive("textStyle", {
    color: editor.getAttributes("textStyle").color || "",
  }),
  value: editor.getAttributes("textStyle").color,
  action: (value) =>
    value && value !== "initial"
      ? editor.chain().focus().setColor(value?.toString()).run()
      : editor.chain().focus().unsetColor().run(),
});
