import { Editor } from "@tiptap/react";
import {
  Columns,
  Plus,
  Rows,
  Table2,
  TableCellsMerge,
  TableCellsSplit,
  Trash,
} from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarTable = (editor: Editor): ToolbarButton[] => [
  {
    key: "insertTable",
    label: "Insert table",
    icon: Plus,
    action: () =>
      editor
        .chain()
        .focus()
        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
        .run(),
  },
  {
    key: "row",
    label: "Row",
    icon: Rows,
    subToolbarButtons: [
      {
        key: "addRowBefore",
        label: "Add before",
        disable: !editor.can().addRowBefore(),
        action: () => editor.chain().focus().addRowBefore().run(),
      },
      {
        key: "addRowAfter",
        label: "Add after",
        disable: !editor.can().addRowAfter(),
        action: () => editor.chain().focus().addRowAfter().run(),
      },
      {
        key: "deleteRow",
        label: "Delete",
        disable: !editor.can().deleteRow(),
        action: () => editor.chain().focus().deleteRow().run(),
      },
    ],
  },
  {
    key: "colomn",
    label: "Column",
    icon: Columns,
    subToolbarButtons: [
      {
        key: "addColumnBefore",
        label: "Add before",
        disable: !editor.can().addColumnBefore(),
        action: () => editor.chain().focus().addColumnBefore().run(),
      },
      {
        key: "addColumnAfter",
        label: "Add after",
        disable: !editor.can().addColumnAfter(),
        action: () => editor.chain().focus().addColumnAfter().run(),
      },
      {
        key: "deleteColumn",
        label: "Delete",
        disable: !editor.can().deleteColumn(),
        action: () => editor.chain().focus().deleteColumn().run(),
      },
    ],
  },
  {
    key: "mergeCells",
    label: "Merge cells",
    icon: TableCellsMerge,
    disable: !editor.can().mergeCells(),
    action: () => editor.chain().focus().mergeCells().run(),
  },
  {
    key: "splitCell",
    label: "Split cell",
    icon: TableCellsSplit,
    disable: !editor.can().splitCell(),
    action: () => editor.chain().focus().splitCell().run(),
  },
  {
    key: "toggleHeader",
    label: "Toggle header",
    icon: Table2,
    subToolbarButtons: [
      {
        key: "toggleHeaderColumn",
        label: "Column",
        disable: !editor.can().toggleHeaderColumn(),
        action: () => editor.chain().focus().toggleHeaderColumn().run(),
      },
      {
        key: "toggleHeaderRow",
        label: "Row",
        disable: !editor.can().toggleHeaderRow(),
        action: () => editor.chain().focus().toggleHeaderRow().run(),
      },
      {
        key: "toggleHeaderCell",
        label: "Cell",
        disable: !editor.can().toggleHeaderCell(),
        action: () => editor.chain().focus().toggleHeaderCell().run(),
      },
    ],
  },
  {
    key: "deleteTable",
    label: "Delete table",
    icon: Trash,
    disable: !editor.can().deleteTable(),
    action: () => editor.chain().focus().deleteTable().run(),
  },
];
