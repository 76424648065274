import api from "@/config/api";
import { AxiosResponse } from "axios";

export interface ConsentMarketing {
  id: number;
  company: string;
  created_at: string;
  email: string;
  name: string;
}

export interface ListConsentMarketing {
  list: ConsentMarketing[];
  totalRows: number;
}

export type ConsentMarketingOrderBy = "createdAt" | "name" | "company";

export const ConsentMarketingService = {
  getListConsentMarketing: async (
    page: number,
    pageSize: number,
    orderBy?: ConsentMarketingOrderBy,
    customerId?: number
  ): Promise<ListConsentMarketing> => {
    try {
      let url = `/consent_marketing?page=${page}&pageSize=${pageSize}`;
      if (orderBy) {
        url += `&orderBy=${orderBy}`;
      }
      if (customerId) {
        url += `&customerId=${customerId}`;
      }
      const response: AxiosResponse<ListConsentMarketing> = await api.get(url);
      return response.data;
    } catch (error) {
      console.error("Error getting list consent marketing:", error);
      throw error;
    }
  },
  deleteMutiple: async (ids: number[]): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.post(
        `/consent_marketing/delete`,
        ids
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting list consent marketing:", error);
      throw error;
    }
  },
  export: async (customerId?: number): Promise<void> => {
    try {
      let url = `/consent_marketing/export`;
      if (customerId) {
        url += `?customerId=${customerId}`;
      }
      const response: AxiosResponse<{ url: string }> = await api.get(url);
      if (response.data.url) {
        window.open(response.data.url);
      }
    } catch (error) {
      console.error("Error export consent marketing:", error);
      throw error;
    }
  },
};
