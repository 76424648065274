import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import {
  Badge,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  Input,
  InputProps,
} from "../resources";
import { X } from "lucide-react";
import { cn } from "@/helper/utils";

interface InputTagProps extends InputProps {
  tags?: string[];
  setTags?: React.Dispatch<React.SetStateAction<string[]>>;
}

const InputTag = React.forwardRef<HTMLInputElement, InputTagProps>(
  ({ tags = [], setTags, className, ...props }, ref) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [dialogTags, setDialogTags] = useState(false);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length > 10) {
        setDialogTags(true);
      } else {
        setInputValue(e.target.value);
      }
    };

    const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" || e.key === ",") {
        e.preventDefault();
        const newTag = inputValue.trim();
        if (newTag && !tags.includes(newTag)) {
          setTags && setTags([...tags, newTag]);
          setInputValue("");
        }
      }
    };

    const removeTag = (indexToRemove: number) => {
      setTags && setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    return (
      <div>
        <Input
          ref={ref}
          value={inputValue}
          placeholder="Type a tag and press Enter"
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          {...props}
        />
        <div
          className={cn(
            "flex gap-2 py-1 flex-wrap",
            tags && tags.length > 0 && "mt-1"
          )}
        >
          {tags.map((tag, index) => (
            <Badge key={index} className="flex gap-2 h-8" variant="outline">
              <span>#{tag}</span>
              <button
                type="button"
                className="remove-tag-button"
                onClick={() => removeTag(index)}
              >
                <X className="w-3 h-3" />
              </button>
            </Badge>
          ))}
        </div>

        <Dialog open={dialogTags} onOpenChange={setDialogTags}>
          <DialogContent className="sm:max-w-[425px]">
            <div>
              <p>최대 10자까지만 입력가능해요.</p>
            </div>
            <DialogFooter className="mt-5">
              <DialogClose asChild>
                <Button type="button" variant="submit" className="w-full">
                  확인
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
);

InputTag.displayName = "InputTag";

export { InputTag };
