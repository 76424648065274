import api from "@/config/api";
import { AxiosResponse } from "axios";

export interface Download {
  id: 0;
  name: string;
  company: string;
  industry: string;
  department: string;
  title: string;
  email: string;
  type: string;
  file_name: string;
  scheduled_delete_at: string;
  created_at: string;
}

export interface ListDownload {
  list: Download[];
  totalRows: number;
}

export type DownloadOrderBy = "createdAt" | "name" | "company" | "type";

export const DownloadService = {
  getListDownload: async (
    page: number,
    pageSize: number,
    orderBy?: DownloadOrderBy,
    customerId?: number
  ): Promise<ListDownload> => {
    try {
      let url = `/download?page=${page}&pageSize=${pageSize}`;
      if (orderBy) {
        url += `&orderBy=${orderBy}`;
      }
      if (customerId) {
        url += `&customerId=${customerId}`;
      }
      const response: AxiosResponse<ListDownload> = await api.get(url);
      return response.data;
    } catch (error) {
      console.error("Error getting list download:", error);
      throw error;
    }
  },
  deleteMutipleDownload: async (
    ids: number[]
  ): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.post(
        `/download/delete`,
        ids
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting list download:", error);
      throw error;
    }
  },
  export: async (customerId?: number): Promise<void> => {
    try {
      let url = `/download/export`;
      if (customerId) {
        url += `?customerId=${customerId}`;
      }
      const response: AxiosResponse<{ url: string }> = await api.get(url);
      if (response.data.url) {
        window.open(response.data.url);
      }
    } catch (error) {
      console.error("Error export download:", error);
      throw error;
    }
  },
};
