import api from "@/config/api";
import { AxiosResponse } from "axios";

export interface ContactUs {
  id: number;
  company: string;
  department: string;
  email: string;
  industry: string;
  inquiry_type: string;
  name: string;
  phone_number: string;
  processing_status: boolean;
  scheduled_delete_at: string;
  title: string;
  solutions: string;
  customer_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface ListContactUs {
  list: ContactUs[];
  totalRows: number;
}

export interface ContactUsDetail {
  id: number;
  details: string;
  processing_history: string;
  processing_updated_at: string;
}

export type ContactUsOrderBy = "createdAt" | "name" | "company" | "inquiryType";

export const ContactUsService = {
  getListContactUs: async (
    page: number,
    pageSize: number,
    search?: string,
    orderBy?: ContactUsOrderBy,
    customerId?: number
  ): Promise<ListContactUs> => {
    try {
      let url = `/contact_us?page=${page}&pageSize=${pageSize}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (orderBy) {
        url += `&orderBy=${orderBy}`;
      }
      if (customerId) {
        url += `&customerId=${customerId}`;
      }
      const response: AxiosResponse<ListContactUs> = await api.get(url);
      return response.data;
    } catch (error) {
      console.error("Error getting list contact us:", error);
      throw error;
    }
  },
  getContactUsDetail: async (id: number): Promise<ContactUsDetail> => {
    try {
      const response: AxiosResponse<ContactUsDetail> = await api.get(
        `/contact_us/${id}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getting contact us:", error);
      throw error;
    }
  },
  updateContactUs: async (
    id: number,
    data: Partial<{ processing_history: string }>
  ): Promise<ContactUsDetail> => {
    try {
      const response: AxiosResponse<ContactUsDetail> = await api.put(
        `/contact_us/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error updating contact us:", error);
      throw error;
    }
  },
  deleteMultipleContactUs: async (
    ids: number[]
  ): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.post(
        `/contact_us/delete`,
        ids
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting multiple contact us:", error);
      throw error;
    }
  },
  export: async (customerId?: number): Promise<void> => {
    try {
      let url = `/contact_us/export`;
      if (customerId) {
        url += `?customerId=${customerId}`;
      }
      const response: AxiosResponse<{ url: string }> = await api.get(url);
      if (response.data.url) {
        window.open(response.data.url);
      }
    } catch (error) {
      console.error("Error export contact us:", error);
      throw error;
    }
  },
};
