import { Page } from "@/components/common";
import { DataTable, SearchData } from "@/components/modules/news";
import { PER_PAGE } from "@/constants";
import { formatDate } from "@/helper/format";
import { Post, PostsService } from "@/services";
import { useEffect, useState } from "react";

const News = () => {
  const [data, setData] = useState<Post[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [selected, setSelected] = useState<number[]>([]);
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [searchText, setSearchText] = useState<string>("");
  const [totalRows, setTotalRows] = useState<number>(0);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const fetchPosts = async (
    page?: number,
    search?: string,
    from?: Date | undefined,
    to?: Date | undefined
  ) => {
    const data = await PostsService.getListPost(
      page ?? 1,
      PER_PAGE,
      search,
      1,
      formatDate(from),
      formatDate(to)
    );
    setData(data.posts || []);
    setTotalPage(Math.ceil(data.totalRows / PER_PAGE));
    setTotalRows(data.totalRows);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <Page title="뉴스룸 관리">
      <div className="space-y-5 mt-6">
        <SearchData
          fetchPosts={fetchPosts}
          fromDate={fromDate}
          searchText={searchText}
          openDialogDelete={openDialogDelete}
          selected={selected}
          setCurrentPage={setCurrentPage}
          setFromDate={setFromDate}
          setOpenDialogDelete={setOpenDialogDelete}
          setSearchText={setSearchText}
          setToDate={setToDate}
          toDate={toDate}
          setSelected={setSelected}
          categoryId={1}
        />
        <DataTable
          currentPage={currentPage}
          data={data}
          fetchPosts={fetchPosts}
          fromDate={fromDate}
          searchText={searchText}
          selected={selected}
          setCurrentPage={setCurrentPage}
          setSelected={setSelected}
          toDate={toDate}
          totalPage={totalPage}
          totalRows={totalRows}
          categoryId={1}
        />
      </div>
    </Page>
  );
};

export default News;
