import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
} from "@/components/resources";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

interface FormChangePasswordProps {
  formSchema: z.Schema<any>;
  onSubmit: SubmitHandler<any>;
}

export const FormChangePassword = ({
  formSchema,
  onSubmit,
}: FormChangePasswordProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      passwordCurrent: "",
      passwordNew: "",
      passwordConfirm: "",
    },
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(
          async (values: z.infer<typeof formSchema>) => {
            await onSubmit(values);
            form.reset();
          }
        )}
        className="space-y-3 max-w-80"
      >
        <FormField
          control={form.control}
          name="passwordCurrent"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="password"
                  placeholder="기존 비밀번호를 입력하세요."
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="passwordNew"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="password"
                  placeholder="새로운 비밀번호를 입력하세요."
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="passwordConfirm"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="password"
                  placeholder="다시 새로운 비밀번호를 입력하세요."
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit" disabled={!form.formState.isValid}>
          비밀번호 변경
        </Button>
      </form>
    </Form>
  );
};
