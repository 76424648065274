export const FONT_SIZE_DEFAULT = 22;
export const FONT_SIZE_TABLET = 15;
export const FONT_SIZE_MOBILE = 14;

export const FONT_FAMILIES = ["Pretendard", "Arial", "Georgia"];
export const FONT_FAMILIY_DEFAULT = "Pretendard";

export const LINE_HEIGHTS = ["1", "1.25", "1.5", "2", "2.5", "3"];
export const LINE_HEIGHT_DEFAULT = "1.25";

export const FONT_WEIGHTS = [
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
];
export const FONT_WEIGHTS_DEFAULT = "400";
