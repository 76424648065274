import { ReactNode } from "react";
import { Button } from "../resources";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface PageProps {
  title?: string;
  children?: ReactNode;
  backAction?: {
    url?: string;
    action?: () => void;
  };
}

export const Page = ({ children, title, backAction }: PageProps) => {
  const navigate = useNavigate();
  return (
    <>
      {backAction && (
        <Button
          variant="outline"
          size="icon"
          onClick={() =>
            backAction?.action
              ? backAction.action()
              : backAction?.url
              ? navigate(backAction?.url)
              : () => {}
          }
          className="mb-3"
        >
          <ArrowLeft />
        </Button>
      )}
      <h3 className="font-[800] text-[28px] mb-8">{title}</h3>
      <div>{children}</div>
    </>
  );
};
