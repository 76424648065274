import { Editor } from "@tiptap/react";

import { FONT_FAMILIES, FONT_FAMILIY_DEFAULT } from "../../constants";
import { ToolbarButton } from "../extensions";

export const toolbarFontFamily = (editor: Editor): ToolbarButton[] => {
  return FONT_FAMILIES.map((fontFamily) => ({
    key: `fontFamily-${fontFamily}`,
    label: fontFamily,
    active:
      fontFamily !== FONT_FAMILIY_DEFAULT
        ? editor.isActive("textStyle", { fontFamily })
        : !FONT_FAMILIES.some((fontFamily) =>
            editor.isActive("textStyle", { fontFamily: fontFamily })
          ),
    action: () => {
      if (fontFamily !== FONT_FAMILIY_DEFAULT) {
        editor.chain().focus().setFontFamily(fontFamily).run();
      } else {
        editor.chain().focus().unsetFontFamily().run();
      }
    },
  }));
};
