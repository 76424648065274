import {
  ButtonPermission,
  DialogDelete,
  IndexTable,
} from "@/components/common";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/resources";
import { PER_PAGE } from "@/constants";
import { formatDateTime } from "@/helper/format";
import { useAppSelector } from "@/lib/hooks";
import { Download, DownloadOrderBy, DownloadService } from "@/services";
import { List } from "lucide-react";
import { useEffect, useState } from "react";

const headers = [
  { title: "번호", clsx: "min-w-16" },
  { title: "이름", clsx: "min-w-48" },
  { title: "회사", clsx: "min-w-24" },
  { title: "산업군", clsx: "min-w-48" },
  { title: "부서", clsx: "min-w-24" },
  { title: "직함", clsx: "min-w-24" },
  { title: "이메일", clsx: "min-w-48" },
  { title: "다운로드 항목", clsx: "min-w-32" },
  { title: "다운로드 파일명", clsx: "min-w-48" },
  { title: "다운로드 일자(개인정보 수집/이용 동의일)", clsx: "min-w-72" },
  { title: "개인정보 삭제 예정일", clsx: "min-w-48" },
];

const sortItems = [
  { value: "createdAt", label: "등록일순" },
  { value: "name", label: "이름순" },
  { value: "company", label: "회사명순" },
  { value: "type", label: "다운로드 항목순" },
];

export const DownloadManager = ({ customerId }: { customerId?: number }) => {
  const unmask = useAppSelector((state) => state.configuration.unmask);

  const [data, setData] = useState<Download[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [selected, setSelected] = useState<number[]>([]);
  const [orderBy, setOrderBy] = useState<DownloadOrderBy>("createdAt");
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const fetchListDownload = async (
    page?: number,
    orderBy?: DownloadOrderBy
  ) => {
    const contactUsRes = await DownloadService.getListDownload(
      page ?? 1,
      PER_PAGE,
      orderBy,
      customerId
    );
    setData(contactUsRes.list || []);
    setTotalPage(Math.ceil(contactUsRes.totalRows / PER_PAGE));
    setTotalRows(contactUsRes.totalRows);
  };

  useEffect(() => {
    fetchListDownload();
  }, [unmask]);

  const handleDeleteMultilple = async () => {
    await DownloadService.deleteMutipleDownload(selected);
    setCurrentPage(1);
    setSelected([]);
    fetchListDownload(1);
    setOpenDialogDelete(false);
  };

  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
    fetchListDownload(page, orderBy);
  };

  const handleSort = async (orderBy: DownloadOrderBy) => {
    setCurrentPage(1);
    fetchListDownload(1, orderBy);
  };

  const handleExportToExcel = async () => {
    await DownloadService.export(customerId);
  };

  const rows = data.map((item, index) => {
    const {
      id,
      name,
      company,
      industry,
      department,
      title,
      email,
      type,
      file_name,
      scheduled_delete_at,
      created_at,
    } = item;
    return {
      id,
      name,
      company,
      industry,
      department,
      title,
      email,
      type,
      file_name,
      createdAt: formatDateTime(new Date(created_at)),
      scheduledDeleteAt: formatDateTime(new Date(scheduled_delete_at)),
    };
  });

  return (
    <div className="space-y-3">
      <div className="flex gap-3 justify-between items-end flex-wrap">
        <h3 className="font-bold text-[28px]">콘텐츠 다운로드</h3>
        <div className="flex gap-x-10 gap-y-4 flex-wrap">
          <div className="flex gap-5 items-center flex-wrap">
            <span>총 {totalRows} 건</span>
            {!customerId && (
              <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-1 focus:outline-none">
                  <List className="w-5 h-5" />
                  <span>
                    {sortItems.find((item) => item.value === orderBy)?.label}
                  </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-36" align="start">
                  <DropdownMenuRadioGroup
                    value={orderBy}
                    onValueChange={(value) => {
                      setOrderBy(value as DownloadOrderBy);
                      handleSort(value as DownloadOrderBy);
                    }}
                  >
                    {sortItems.map((item, key) => (
                      <DropdownMenuRadioItem key={key} value={item.value}>
                        <span>{item.label}</span>
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>

          <div className="flex flex-wrap gap-3">
            <ButtonPermission
              title="엑셀 다운로드"
              action={handleExportToExcel}
              contentDialog={
                <p>Super Admin 계정으로 로그인해야만 다운로드가 가능합니다.</p>
              }
            />
            <Button
              disabled={selected.length === 0}
              onClick={() => setOpenDialogDelete(true)}
            >
              고객정보 삭제
            </Button>
          </div>
        </div>
      </div>

      <IndexTable
        selected={selected}
        setSelected={setSelected}
        headers={headers}
        rows={rows}
        pagination={{
          current: currentPage,
          total: totalPage,
          onPrev: () => handleChangePage(currentPage > 1 ? currentPage - 1 : 1),
          onNext: () =>
            handleChangePage(
              currentPage < totalPage ? currentPage + 1 : totalPage
            ),
          onPage: handleChangePage,
        }}
      />
      <DialogDelete
        open={openDialogDelete}
        setOpen={setOpenDialogDelete}
        onSubmit={handleDeleteMultilple}
      />
    </div>
  );
};
