import { Dispatch, ReactNode, SetStateAction } from "react";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
} from "../resources";

interface DialogPermissionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  content?: string | ReactNode;
}
export const DialogPermission = ({
  open = false,
  setOpen,
  content = "",
}: DialogPermissionProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[500px]">
        <div>{content}</div>
        <DialogFooter className="mt-5">
          <DialogClose asChild>
            <Button type="button" variant="submit">
              확인
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
