import { Editor } from "@tiptap/react";
import { PaintBucket } from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarBackgroundColor = (editor: Editor): ToolbarButton => ({
  key: "backgroundColor",
  label: "Background color",
  icon: PaintBucket,
  active: editor.isActive("textStyle", {
    backgroundColor: editor.getAttributes("textStyle").backgroundColor || "",
  }),
  value: editor.getAttributes("textStyle").backgroundColor,
  action: (value) =>
    value && value !== "initial"
      ? editor.chain().focus().setBackgroundColor(value?.toString()).run()
      : editor.chain().focus().unsetBackgroundColor().run(),
});
