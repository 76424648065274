import { Page } from "@/components/common";
import {
  ConsentMarketingManagement,
  ContactUsManagement,
  DownloadManager,
} from "@/components/modules/customers";
import DashboardCustomer from "@/components/modules/customers/DashboardCustomer";
import { Separator } from "@/components/resources";
import { CustomerService, CustomerStatistics } from "@/services";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CustomerInformation = () => {
  const navigate = useNavigate();
  const { id: customerId } = useParams();

  const [customerStatistics, setCustomerStatistics] =
    useState<CustomerStatistics>();

  const fetchData = async () => {
    try {
      const customerStatisticsRes = await CustomerService.getCustomerStatistics(
        Number(customerId)
      );
      setCustomerStatistics(customerStatisticsRes);
    } catch (error) {
      navigate("/customers");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="CRM > 고객 상세 정보" backAction={{ url: "/customers" }}>
      {customerStatistics?.email && (
        <>
          <DashboardCustomer customerStatistics={customerStatistics} />
          <Separator className="my-8" />
          <ContactUsManagement customerId={Number(customerId)} />
          <Separator className="my-8" />
          <DownloadManager customerId={Number(customerId)} />
          <Separator className="my-8" />
          <ConsentMarketingManagement customerId={Number(customerId)} />
        </>
      )}
    </Page>
  );
};

export default CustomerInformation;
