import { Ban, ChevronRight, Palette } from "lucide-react";
import React from "react";

import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  buttonVariants,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { DEFAULT_COLORS, DEFAULT_COLORS_CUSTOM } from "./constants/color";

interface ColorPickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line no-unused-vars
  handleChange?: (value: string) => void;
}

const ColorPicker = React.forwardRef<HTMLInputElement, ColorPickerProps>(
  ({ value = "#00000", handleChange, children, ...props }, ref) => {
    return (
      <Popover>
        <PopoverTrigger asChild>
          {children || (
            <Button
              type="button"
              variant="outline"
              size="icon"
              className="h-8 w-8"
              style={{ color: value?.toString() ?? "#00000" }}
            >
              <Palette className="h-4 w-4" />
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="relative min-w-max space-y-2">
          <div className="relative">
            <input
              ref={ref}
              id="colorPicker"
              type="color"
              className="absolute -right-2 bottom-1/2 h-0 w-0 opacity-0"
              value={value}
              onChange={(e) => handleChange && handleChange(e.target.value)}
              {...props}
            />
            <label
              htmlFor="colorPicker"
              className={cn(
                "w-full cursor-pointer",
                buttonVariants({ variant: "outline" })
              )}
            >
              Customize
              <ChevronRight className="h-4 w-4" />
            </label>
          </div>
          <Separator />

          <div className="grid grid-cols-[repeat(10,1fr)] gap-1">
            <Button
              size="icon"
              className={cn("h-6 w-6 bg-transparent hover:bg-transparent")}
              onClick={() => handleChange && handleChange("initial")}
            >
              <Ban className="h-5 w-5 text-primary" />
            </Button>
            {DEFAULT_COLORS_CUSTOM.map((item) => (
              <Button
                key={item.name}
                style={{ backgroundColor: item.value }}
                size="icon"
                className={cn(
                  "h-6 w-6 border border-muted",
                  item.value === value && "border-primary"
                )}
                onClick={() => handleChange && handleChange(item.value)}
              />
            ))}
          </div>
          <Separator />
          <div className="grid grid-cols-[repeat(10,1fr)] gap-1">
            {DEFAULT_COLORS.map((item) => (
              <Button
                key={item.name}
                style={{ backgroundColor: item.value }}
                size="icon"
                className={cn(
                  "h-6 w-6 border border-muted",
                  item.value === value && "border-primary"
                )}
                onClick={() => handleChange && handleChange(item.value)}
              />
            ))}
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

ColorPicker.displayName = "ColorPicker";

export { ColorPicker };
