import { Editor } from "@tiptap/react";
import { ChangeEvent, useEffect, useState } from "react";

import {
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  buttonVariants,
} from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarImage } from "../helper/toolbar";
import { usePreventEditorFocus } from "../hooks";
import { PostsService } from "@/services";

export const ToolbarImage = ({ editor }: { editor: Editor }) => {
  const [value, setValue] = useState("");
  const { inputRef, setIsFocused } = usePreventEditorFocus(editor);

  const {
    key,
    label,
    value: valueAttr,
    action,
    disable,
    active,
    ...item
  } = toolbarImage(editor);

  useEffect(() => {
    setValue("");
  }, [valueAttr]);

  const handleUploadImage = async (file: File) => {
    try {
      const newFormData = new FormData();
      newFormData.append("image", file);
      const response = await PostsService.uploadImage(newFormData);
      if (response && response.path) {
        console.log(response.path);

        action && action(response.path);
      }
    } catch (error) {
      const reader = new FileReader();
      reader.onloadend = () => {
        action && action(reader.result?.toString());
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleUploadImage(file);
    }
  };

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            key={key}
            type="button"
            variant={active ? "default" : "outline"}
            size={item.icon ? "icon" : "default"}
            disabled={disable}
            className={cn("h-8", item.icon && "w-8")}
          >
            {item.icon ? <item.icon className="h-4 w-4" /> : label}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex max-w-max flex-wrap gap-2 p-2">
          <Input
            ref={inputRef}
            placeholder="Enter url"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="h-8 w-48"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <Button className="h-8" onClick={() => action && action(value)}>
            Save
          </Button>

          <Separator />

          <input
            type="file"
            name="image"
            id="chooseImage"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />
          <label
            htmlFor="chooseImage"
            className={cn(buttonVariants(), "h-8 w-full cursor-pointer")}
          >
            Choose Image
          </label>
        </PopoverContent>
      </Popover>
    </>
  );
};
