import { Editor } from "@tiptap/react";
import { Link2 } from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarLink = (editor: Editor): ToolbarButton => ({
  key: "link",
  label: "Link",
  icon: Link2,
  active: editor.isActive("link"),
  value: editor.getAttributes("link").href,
  action: (value) => {
    if (value) {
      const { state } = editor;
      const { selection } = state;
      const { from, to } = selection;
      const selectedText = state.doc.textBetween(from, to, " ");

      if (selectedText) {
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: value.toString() })
          .run();
      } else {
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: value.toString() })
          .insertContent(String(value))
          .run();
      }
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
  },
});
