import {
  DatePicker,
  InputColor,
  InputFile,
  InputTag,
  TextEditor,
} from "@/components/common";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  buttonVariants,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from "@/components/resources";
import { PostsService } from "@/services";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { formatDate, parseDate } from "@/helper/format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cn } from "@/helper/utils";

// const imageFileSchema = z.any().refine(
//   async (file: File) => {
//     if (!file) {
//       return true;
//     }
//     const isValidImage = await new Promise((resolve) => {
//       const fileURL = URL.createObjectURL(file);
//       const img = new Image();
//       img.src = fileURL;
//       img.onload = () => {
//         if (
//           img.width <= 1280 &&
//           img.height <= 1280 &&
//           file.size <= 2 * 1024 * 1024
//         ) {
//           resolve(true);
//         } else {
//           resolve(false);
//         }
//       };
//     });
//     return isValidImage;
//   },
//   {
//     message:
//       "Invalid image URL. Please make sure the image has dimensions of 1280px x 1280px and size 2MB.",
//   }
// );

const tagSchema = z
  .string()
  .max(10, "Each tag must be at most 10 characters long");

interface FormCreateOrEditProps {
  categoryId: 1 | 2;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  openDialogRedirect: boolean;
  setOpenDialogRedirect: Dispatch<SetStateAction<boolean>>;
}

export const FormCreateOrEdit = ({
  categoryId,
  setIsDirty,
  openDialogRedirect,
  setOpenDialogRedirect,
}: FormCreateOrEditProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [imageFilePreview, setImageFilePreview] = useState<string>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dialogRequired, setDialogRequired] = useState(false);

  const formSchema = z.object({
    releaseDate: z.date().optional(),
    title: z.string().min(1, {
      message: "Required.",
    }),
    subTitle:
      categoryId === 2
        ? z.string().optional()
        : z.string().min(1, {
            message: "Required.",
          }),
    type:
      categoryId === 2
        ? z.string().min(1, {
            message: "Required.",
          })
        : z.string().optional(),
    content: z
      .string()
      .min(1, { message: "Required." })
      .refine((value) => value !== `<p></p>`, {
        message: "Required.",
      }),
    link: z
      .string()
      .optional()
      .refine(
        (value) => value === "" || z.string().url().safeParse(value).success,
        {
          message: "Invalid URL.",
        }
      ),
    summary:
      categoryId === 2
        ? z.string().optional()
        : z.string().min(1, {
            message: "Required.",
          }),
    titleEn: z.string().optional(),
    summaryEn: z.string().optional(),
    tags: z
      .array(tagSchema)
      .max(5, "You can only provide up to 5 tags")
      .optional(),
    image:
      id && imageFilePreview
        ? z.any().optional()
        : z.any().refine((file) => file !== undefined && file !== null, {
            message: "Image field is required.",
          }),
    color_code:
      categoryId === 2
        ? z.string().min(1, {
            message: "Required.",
          })
        : z.string().optional(),
    color_code_title: z.string().optional(),
    color_code_type: z.string().optional(),
  });

  const defaultValues = useRef({
    releaseDate: new Date(),
    title: "",
    type: "",
    content: "",
    link: "",
    summary: "",
    titleEn: "",
    summaryEn: "",
    tags: [] as string[],
    image: null,
    color_code: "",
    color_code_title: "",
    color_code_type: "",
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues.current,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const fetchData = async () => {
    setLoading(true);
    if (id) {
      try {
        const post = await PostsService.getPost(Number(id));
        if (post && post.id) {
          const {
            thumb_img,
            release_date,
            title,
            sub_title,
            type,
            tags,
            content,
            link,
            summary,
            title_en,
            summary_en,
            color_code,
            color_code_title,
            color_code_type,
          } = post;

          const newDefaultValues = {
            releaseDate: parseDate(release_date) || new Date(),
            title: title,
            subTitle: sub_title || "",
            type: type || "",
            content: content,
            tags: tags ? tags.split(",") : [],
            image: null,
            link: link,
            summary: summary,
            titleEn: title_en,
            summaryEn: summary_en,
            color_code: color_code || "",
            color_code_title: color_code_title || "",
            color_code_type: color_code_type || "",
          };

          defaultValues.current = newDefaultValues;
          form.reset(newDefaultValues);

          setImageFilePreview(thumb_img);
        }
      } catch (error) {
        navigate("/news");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty]);

  useEffect(() => {
    const { title, content, image, summary } = form.formState.errors;

    if (title || content || image || summary) {
      setDialogRequired(true);
    }
  }, [form.formState.errors]);

  const onSubmit = () => {
    setOpen(true);
  };

  const titleInputRef = useRef<HTMLInputElement>(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleSave = async (isDraft = false) => {
    if (isDraft) {
      form.clearErrors();
      await form.trigger("title");
      if (form.formState.errors.title) {
        if (titleInputRef.current) {
          titleInputRef.current.focus();
        }
        setOpenDialogRedirect(false);
        setDialogRequired(true);
        return;
      }
    }
    const formData = form.getValues();
    if (formData) {
      const {
        releaseDate,
        title,
        subTitle,
        type,
        content,
        tags,
        image,
        link,
        summary,
        titleEn,
        summaryEn,
        color_code,
        color_code_title,
        color_code_type,
      } = formData;

      const newFormData = new FormData();
      newFormData.append("release_date", formatDate(releaseDate));
      newFormData.append("category_id", String(categoryId));
      newFormData.append("title", title.trim());
      newFormData.append("sub_title", subTitle?.trim() || "");
      newFormData.append("type", type || "");
      newFormData.append("content", content);
      newFormData.append("status", String(true));
      newFormData.append("link", link || "");
      newFormData.append("summary", summary?.trim() || "");
      newFormData.append("title_en", titleEn?.trim() || "");
      newFormData.append("summary_en", summaryEn?.trim() || "");
      newFormData.append("color_code", color_code?.trim() || "");
      newFormData.append("color_code_title", color_code_title?.trim() || "");
      newFormData.append("color_code_type", color_code_type?.trim() || "");

      if (tags) {
        newFormData.append("tags", tags.join(","));
      }
      if (image) {
        newFormData.append("image", image);
      }
      setIsSubmiting(true);
      try {
        const post = !id
          ? isDraft
            ? await PostsService.createPostDraft(newFormData)
            : await PostsService.createPost(newFormData)
          : isDraft
          ? await PostsService.updatePostDraft(Number(id), newFormData)
          : await PostsService.updatePost(Number(id), newFormData);
        if (post && post.id) {
          setIsSubmiting(false);
          navigate(categoryId === 2 ? "/news/organization" : "/news");
        }
      } catch (error) {
        setIsSubmiting(false);
      }
    }
  };

  return (
    <>
      {!loading && (
        <>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="releaseDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>발행일</FormLabel>
                    <DatePicker value={field.value} onChange={field.onChange} />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-wrap gap-3">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormLabel required>제목</FormLabel>
                      <FormControl ref={titleInputRef}>
                        <Input
                          maxLength={100}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            if (form.formState.errors.title) {
                              form.trigger("title");
                            }
                          }}
                        />
                      </FormControl>
                      <FormDescription>
                        글자수 제한{" "}
                        <span className="text-red-600">
                          {field?.value?.length ?? 0}
                        </span>
                        /100
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {categoryId === 2 && (
                  <FormField
                    control={form.control}
                    name="color_code_title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="opacity-0">
                          제목 색상등록
                        </FormLabel>
                        <FormControl>
                          <InputColor
                            placeholder="#000000"
                            {...field}
                            handleChange={(value) => field.onChange(value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </div>

              {categoryId === 1 && (
                <FormField
                  control={form.control}
                  name="subTitle"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>소제목</FormLabel>
                      <FormControl>
                        <Input maxLength={100} {...field} />
                      </FormControl>
                      <FormDescription>
                        글자수 제한{" "}
                        <span className="text-red-600">
                          {field?.value?.length ?? 0}
                        </span>
                        /100
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {categoryId === 2 && (
                <div className="flex gap-3">
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel required>카테고리</FormLabel>
                        <FormControl>
                          <Input maxLength={20} {...field} />
                        </FormControl>
                        <FormDescription>
                          글자수 제한{" "}
                          <span className="text-red-600">
                            {field?.value?.length ?? 0}
                          </span>
                          /20
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="color_code_type"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="opacity-0">
                          카테고리 색상 등록
                        </FormLabel>
                        <FormControl>
                          <InputColor
                            placeholder="#91004E"
                            {...field}
                            handleChange={(value) => field.onChange(value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
              <FormField
                control={form.control}
                name="content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>내용</FormLabel>
                    <FormControl>
                      <TextEditor
                        value={field.value}
                        setValue={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {categoryId === 1 && (
                <>
                  <FormField
                    control={form.control}
                    name="link"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>기사 원문 URL</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="summary"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel required>기사 요약</FormLabel>
                        <FormControl>
                          <Textarea {...field} maxLength={450} />
                        </FormControl>
                        <FormDescription>
                          글자수 제한{" "}
                          <span className="text-red-600">
                            {field?.value?.length ?? 0}
                          </span>
                          /450
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}

              <FormField
                control={form.control}
                name="titleEn"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>영문 제목</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {categoryId === 1 && (
                <FormField
                  control={form.control}
                  name="summaryEn"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>영문 기사 요약</FormLabel>
                      <FormControl>
                        <Textarea {...field} maxLength={450} />
                      </FormControl>
                      <FormDescription>
                        글자수 제한{" "}
                        <span className="text-red-600">
                          {field?.value?.length ?? 0}
                        </span>
                        /450
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="tags"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>해시태그</FormLabel>
                    <FormControl>
                      <InputTag
                        tags={field.value}
                        setTags={field.onChange}
                        placeholder="해시태그를 한 개 씩 입력후 엔터키를 눌러주세요. (최대 5개)"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {categoryId === 2 && (
                <FormField
                  control={form.control}
                  name="color_code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>배너 색상등록</FormLabel>
                      <FormControl>
                        <InputColor
                          placeholder="#000000"
                          {...field}
                          handleChange={(value) => field.onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>
                      {categoryId === 2 ? "썸네일/배너 등록" : "썸네일 등록"}
                    </FormLabel>
                    <FormControl>
                      <InputFile
                        categoryId={categoryId}
                        imageFilePreview={imageFilePreview}
                        id="picture"
                        {...field}
                        accept="image/*"
                        onChange={(e) => field.onChange(e.target.files?.[0])}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex gap-4 justify-center">
                <Button
                  variant="outline-submit"
                  type="button"
                  className="w-40"
                  onClick={() => handleSave(true)}
                  disabled={isSubmiting}
                >
                  임시 저장
                </Button>
                <Button type="submit" variant="submit" className="w-40">
                  발행
                </Button>
              </div>
            </form>
          </Form>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="sm:max-w-[425px]">
              <div>
                <p>기사를 등록 하시겠습니까?</p>
              </div>
              <DialogFooter className="mt-5">
                <DialogClose asChild>
                  <Button type="button" variant="secondary">
                    취소
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  onClick={() => handleSave()}
                  variant="submit"
                  disabled={isSubmiting}
                >
                  확인
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openDialogRedirect}
            onOpenChange={setOpenDialogRedirect}
          >
            <DialogContent className="sm:max-w-[425px]">
              <div>
                <p>작성 중인 기사가 있습니다.</p>
                <p>임시 저장 후 페이지를 이동하시겠습니까?</p>
              </div>
              <DialogFooter className="mt-5">
                <DialogClose asChild>
                  <Link
                    to={categoryId === 2 ? "/news/organization" : "/news"}
                    className={cn(buttonVariants({ variant: "secondary" }))}
                  >
                    페이지 이동
                  </Link>
                </DialogClose>
                <Button
                  type="submit"
                  onClick={() => handleSave(true)}
                  variant="submit"
                  disabled={isSubmiting}
                >
                  임시 저장
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog open={dialogRequired} onOpenChange={setDialogRequired}>
            <DialogContent className="sm:max-w-[425px]">
              <div>
                <p>필수 항목들을 모두 입력하셔야 합니다.</p>
              </div>
              <DialogFooter className="mt-5">
                <DialogClose asChild>
                  <Button type="button" variant="submit" className="w-full">
                    확인
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};
