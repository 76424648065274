import { Page } from "@/components/common";
import { FormCreateOrEdit } from "@/components/modules/news";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const NewsCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [openDialogRedirect, setOpenDialogRedirect] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (isDirty) {
        setOpenDialogRedirect(true);
      } else {
        window.history.back();
      }
    };

    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isDirty]);

  return (
    <Page
      title={`Newsroom > ${id ? "Edit" : "Create"}`}
      backAction={{
        action: () =>
          isDirty ? setOpenDialogRedirect(true) : navigate("/news"),
      }}
    >
      <FormCreateOrEdit
        categoryId={1}
        setIsDirty={setIsDirty}
        openDialogRedirect={openDialogRedirect}
        setOpenDialogRedirect={setOpenDialogRedirect}
      />
    </Page>
  );
};

export default NewsCreate;
