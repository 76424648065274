import {
  Pagination as PaginationShadcn,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/resources";

export interface PaginationProps {
  onPrev: () => void;
  onNext: () => void;
  onPage: (page: number) => void;
  total: number;
  current: number;
}

export const Pagination = ({
  total = 0,
  current = 1,
  onPrev,
  onNext,
  onPage,
}: PaginationProps) => {
  return (
    <PaginationShadcn>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            isActive={current !== 1}
            onClick={current !== 1 ? onPrev : () => {}}
          />
        </PaginationItem>
        {total > 8 ? (
          <>
            {[
              ...new Array(
                total > 4 && current <= 4 ? 5 : current <= 4 ? total : 1
              ),
            ].map((_, index) => (
              <PaginationItem key={index}>
                <PaginationLink
                  isActive={current === index + 1}
                  onClick={() => onPage(index + 1)}
                >
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            {current > 4 && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}
            {current > 4 &&
              total - current - 4 > 0 &&
              [...new Array(total - 4 > 3 ? 3 : total)].map((_, index) => (
                <PaginationItem key={index}>
                  <PaginationLink
                    isActive={current === index + current - 1}
                    onClick={() => onPage(index + current - 1)}
                  >
                    {index + current - 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            {total - current - 4 > 0 && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}

            {[...new Array(total - current - 4 <= 0 ? 5 : 1)].map(
              (_, index) => (
                <PaginationItem key={index}>
                  <PaginationLink
                    isActive={
                      current ===
                      (total - current - 4 <= 0 ? total - 4 + index : total)
                    }
                    onClick={() =>
                      onPage(
                        total - current - 4 <= 0 ? total - 4 + index : total
                      )
                    }
                  >
                    {total - current - 4 <= 0 ? total - 4 + index : total}
                  </PaginationLink>
                </PaginationItem>
              )
            )}
          </>
        ) : (
          [...new Array(total)].map((_, index) => (
            <PaginationItem key={index}>
              <PaginationLink
                isActive={current === index + 1}
                onClick={() => onPage(index + 1)}
                className="h-8 w-8"
              >
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))
        )}
        <PaginationItem>
          <PaginationNext
            isActive={current !== total}
            onClick={current !== total ? onNext : () => {}}
          />
        </PaginationItem>
      </PaginationContent>
    </PaginationShadcn>
  );
};
