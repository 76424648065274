"use client";

import * as React from "react";
import { Calendar as CalendarIcon } from "lucide-react";
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../resources";
import { cn } from "@/helper/utils";
import { formatDate } from "@/helper/format";

interface DatePickerProps {
  value: Date | undefined;
  onChange: React.Dispatch<React.SetStateAction<Date | undefined>>;
  fromDate?: Date;
  toDate?: Date;
}

export const DatePicker = ({
  value,
  onChange,
  fromDate,
  toDate,
}: DatePickerProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-between text-left font-normal",
            !value && "text-muted-foreground"
          )}
        >
          {value ? formatDate(value) : <span>날짜를 선택해 주세요</span>}
          <CalendarIcon className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={value}
          onSelect={onChange}
          initialFocus
          fromDate={fromDate}
          toDate={toDate}
        />
      </PopoverContent>
    </Popover>
  );
};
