import api from "@/config/api";
import { AxiosResponse } from "axios";
import { getEnv } from "../config/env";

const REACT_APP_API_URL = getEnv("REACT_APP_API_URL");

export interface Post {
  id: number;
  category_name: string;
  title: string;
  sub_title: string;
  type?: string;
  is_draft: true;
  release_date: string;
  status: true;
  content: string;
  link: string;
  summary: string;
  title_en: string;
  summary_en: string;
  tags: string;
  thumb_img: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  color_code?: string;
  color_code_title?: string;
  color_code_type?: string;
}

export interface ListPost {
  posts: Post[];
  totalRows: number;
}

export const PostsService = {
  getListPost: async (
    page: number,
    pageSize: number,
    search?: string,
    categoryId?: 1 | 2,
    fromDate?: string,
    toDate?: string
  ): Promise<ListPost> => {
    try {
      let url = `/posts?page=${page}&pageSize=${pageSize}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (categoryId) {
        url += `&categoryId=${categoryId}`;
      }
      if (fromDate) {
        url += `&fromDate=${fromDate}`;
      }
      if (toDate) {
        url += `&toDate=${toDate}`;
      }
      const response: AxiosResponse<ListPost> = await api.get(url);
      return response.data;
    } catch (error) {
      console.error("Error getting post:", error);
      throw error;
    }
  },

  createPost: async (postData: FormData): Promise<Post> => {
    try {
      const response: AxiosResponse<Post> = await api.post(`/posts`, postData);
      return response.data;
    } catch (error) {
      console.error("Error creating post:", error);
      throw error;
    }
  },

  createPostDraft: async (postData: FormData): Promise<Post> => {
    try {
      const response: AxiosResponse<Post> = await api.post(
        `/posts/draft`,
        postData
      );
      return response.data;
    } catch (error) {
      console.error("Error creating post draft:", error);
      throw error;
    }
  },

  getPost: async (postId: number): Promise<Post> => {
    try {
      const response: AxiosResponse<Post> = await api.get(`/posts/${postId}`);
      return response.data;
    } catch (error) {
      console.error("Error getting post:", error);
      throw error;
    }
  },

  updatePost: async (postId: number, postData: FormData): Promise<Post> => {
    try {
      const response: AxiosResponse<Post> = await api.put(
        `/posts/${postId}`,
        postData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating post:", error);
      throw error;
    }
  },

  updatePostDraft: async (
    postId: number,
    postData: FormData
  ): Promise<Post> => {
    try {
      const response: AxiosResponse<Post> = await api.put(
        `/posts/draft/${postId}`,
        postData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating post draft:", error);
      throw error;
    }
  },

  deletePost: async (postId: number): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.delete(
        `/posts/${postId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting post:", error);
      throw error;
    }
  },
  deleteMultiplePost: async (
    postIds: number[]
  ): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> = await api.post(
        `/posts/delete`,
        postIds
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting multiple post:", error);
      throw error;
    }
  },

  updatePostStatus: async (postId: number): Promise<Post> => {
    try {
      const response: AxiosResponse<Post> = await api.put(
        `/posts/${postId}/status`
      );
      return response.data;
    } catch (error) {
      console.error("Error updating post:", error);
      throw error;
    }
  },

  uploadImage: async (data: FormData): Promise<{ path: string }> => {
    try {
      const response: AxiosResponse<{ path: string }> = await api.post(
        "/posts/upload_image",
        data
      );
      let path = "";
      if (response.data && response.data.path) {
        path = `${REACT_APP_API_URL}/public/image?path=${response.data.path}`;
      }
      return { path };
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  },
};
