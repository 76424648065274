import { Editor } from "@tiptap/react";
import { Eye } from "lucide-react";
import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
  Separator,
} from "@/components/resources";

import { adjustFontSize, insertBreakOutput } from "../helper/common";

export const ToolbarPreview = ({ editor }: { editor: Editor }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          type="button"
          variant="outline"
          size="icon"
          className="h-8 w-8"
          disabled={isFullscreen}
        >
          <Eye className="h-4 w-4" />
        </Button>
      </DialogTrigger>

      <DialogContent className="h-[calc(100vh)] min-w-[calc(100%-2rem)] sm:h-[calc(100vh-2rem)]">
        <DialogHeader>
          <DialogTitle>미리보기 페이지입니다.</DialogTitle>
        </DialogHeader>
        <Separator />
        <ScrollArea>
          <div
            className="text-editor--content max-w-[1200px] mx-auto py-6"
            dangerouslySetInnerHTML={{
              __html: adjustFontSize(insertBreakOutput(editor.getHTML())),
            }}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
