import { Editor } from "@tiptap/react";
import { AlignCenter, AlignJustify, AlignLeft, AlignRight } from "lucide-react";

import { ToolbarButton } from "../extensions";

export const toolbarTextAlign = (editor: Editor): ToolbarButton[] => [
  {
    key: "left",
    label: "Left",
    icon: AlignLeft,
    active: editor.isActive({ textAlign: "left" }),
    action: () => editor.chain().focus().setTextAlign("left").run(),
  },
  {
    key: "center",
    label: "Center",
    icon: AlignCenter,
    active: editor.isActive({ textAlign: "center" }),
    action: () => editor.chain().focus().setTextAlign("center").run(),
  },
  {
    key: "right",
    label: "Right",
    icon: AlignRight,
    active: editor.isActive({ textAlign: "right" }),
    action: () => editor.chain().focus().setTextAlign("right").run(),
  },
  {
    key: "justify",
    label: "Justify",
    icon: AlignJustify,
    active: editor.isActive({ textAlign: "justify" }),
    action: () => editor.chain().focus().setTextAlign("justify").run(),
  },
];
