import { Editor } from "@tiptap/react";
import { useEffect, useState } from "react";

import { Button } from "@/components/resources";
import { cn } from "@/helper/utils";

import { ColorPicker } from "../../ColorPicker";
import { toolbarBackgroundColor } from "../helper/toolbar";
import { usePreventEditorFocus } from "../hooks";

export const ToolbarBackgroundColor = ({ editor }: { editor: Editor }) => {
  const { inputRef, setIsFocused } = usePreventEditorFocus(editor);
  const [value, setValue] = useState("#000");

  const {
    key,
    label,
    value: valueAttr,
    action,
    disable,
    ...item
  } = toolbarBackgroundColor(editor);

  useEffect(() => {
    if (valueAttr) {
      setValue(valueAttr.toString());
    } else {
      setValue("#000");
    }
  }, [valueAttr]);

  const handleChangeColor = (value: string) => {
    action && action(value);
  };

  return (
    <ColorPicker
      ref={inputRef}
      value={value}
      handleChange={handleChangeColor}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <Button
        key={key}
        type="button"
        variant="outline"
        size={item.icon ? "icon" : "default"}
        disabled={disable}
        className={cn("h-8", item.icon && "w-8")}
        style={{ color: value?.toString() ?? "#000" }}
      >
        {item.icon ? <item.icon className="h-4 w-4" /> : label}
      </Button>
    </ColorPicker>
  );
};
