import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/resources";
import { Pagination, PaginationProps } from "./Pagination";
import { cn } from "@/helper/utils";
import { useState } from "react";

interface Header {
  title?: string;
  clsx?: string;
}

interface Row {
  id: number;
  [key: string]: any;
}

interface IndexTableProps {
  headers: Header[];
  rows: Row[];
  pagination?: PaginationProps;
  selected?: number[];
  setSelected?: React.Dispatch<React.SetStateAction<number[]>>;
  onClickRow?: (id: number) => void;
  onDoubleClickRow?: (id: number) => void;
}

export const IndexTable = ({
  selected = [],
  headers,
  rows = [],
  pagination,
  setSelected,
  onClickRow,
  onDoubleClickRow,
}: IndexTableProps) => {
  const [clickedRowId, setClickedRowId] = useState<number>();

  const handleClickRow = (id: number) => {
    setClickedRowId(id);
    onClickRow && onClickRow(id);
  };

  const handleSelected = (value: number | "all") => {
    setSelected &&
      setSelected((preSelected) => {
        if (value === "all") {
          if (preSelected.length === rows.length) {
            return [];
          } else {
            return rows.map((item) => item.id);
          }
        }
        const checkExist = preSelected.find((item) => item === value);
        if (checkExist) {
          return preSelected.filter((item) => item !== value);
        } else {
          return [...preSelected, value];
        }
      });
  };

  return (
    <div className="space-y-5">
      <Table className="whitespace-pre-wrap break-keep">
        <TableHeader>
          <TableRow>
            <TableHead>
              <Checkbox
                checked={rows.length !== 0 && selected.length === rows.length}
                onCheckedChange={(_) => handleSelected("all")}
              />
            </TableHead>
            {headers.map((header, key) => (
              <TableHead
                key={key}
                className={cn("font-bold text-black", header.clsx)}
              >
                {header.title}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={headers.length + 1} className="text-center">
                내용이 없습니다.
              </TableCell>
            </TableRow>
          ) : (
            rows.map((item) => (
              <TableRow
                key={item.id}
                onClick={() => handleClickRow(item.id)}
                onDoubleClick={() =>
                  onDoubleClickRow && onDoubleClickRow(item.id)
                }
                className={cn("h-16", onClickRow && "cursor-pointer")}
                data-state={clickedRowId === item.id && "selected"}
              >
                <TableCell>
                  <Checkbox
                    checked={selected?.includes(item.id)}
                    onCheckedChange={(_) => handleSelected(Number(item.id))}
                  />
                </TableCell>
                {Object.values(item).map((value, key) => (
                  <TableCell key={key}>{value}</TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {pagination && <Pagination {...pagination} />}
    </div>
  );
};
