import { cn } from "@/helper/utils";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../resources";

const links = [
  {
    title: "뉴스룸 관리",
    url: "/news",
    subLinks: [
      {
        title: "언론보도 관리",
        url: "/news",
        active: ["/news", "/news/create", "/news/edit/[id]"],
      },
      {
        title: "블록오디세이소식 관리",
        url: "/news/organization",
        active: [
          "/news/organization",
          "/news/organization/create",
          "/news/organization/edit/[id]",
        ],
      },
    ],
  },
  // {
  //   title: "리서치 관리",
  //   url: "/",
  //   active: [],
  // },
  {
    title: "CRM",
    url: "/customers",
    active: ["/customers", "/customers/:id"],
  },
];

const Navigation = () => {
  const currentLocation = useLocation();
  const { id } = useParams();

  const isLinkActive = (links?: string[]) => {
    if (!links) {
      return false;
    }
    return links.some(
      (activeUrl) =>
        currentLocation.pathname === activeUrl.replace("[id]", String(id))
    );
  };

  return (
    <nav className="flex flex-col gap-4">
      {links.map(({ url, active, title, subLinks }, key) =>
        subLinks && subLinks?.length > 0 ? (
          <Collapsible
            key={key}
            open={subLinks.some((item) => isLinkActive(item.active))}
          >
            <CollapsibleTrigger>
              <Link to={url}>{title}</Link>
            </CollapsibleTrigger>
            <CollapsibleContent className="flex flex-col pl-4 pt-3 gap-y-3">
              {subLinks.map((item, subKey) => (
                <Link
                  key={subKey}
                  to={item.url}
                  className={cn(
                    "hover:text-[#9BEFFA] active:text-[#9BEFFA]",
                    isLinkActive(item.active) && "text-[#9BEFFA]"
                  )}
                >
                  {item.title}
                </Link>
              ))}
            </CollapsibleContent>
          </Collapsible>
        ) : (
          <Link
            key={key}
            to={url}
            className={cn(
              "hover:text-[#FFB800] active:text-[#FFB800]",
              isLinkActive(active) && "text-[#FFB800]"
            )}
          >
            {title}
          </Link>
        )
      )}
    </nav>
  );
};

export default Navigation;
