import { Editor } from "@tiptap/react";

import { Button, Card, Separator } from "@/components/resources";
import { cn } from "@/helper/utils";

import { toolbarButtons } from "../helper/toolbar";
import { ToolbarBackgroundColor } from "./ToolbarBackgroundColor";
import { ToolbarColor } from "./ToolbarColor";
import { ToolbarFontFamily } from "./ToolbarFontFamily";
import { ToolbarFontSize } from "./ToolbarFontSize";
import { ToolbarFontWeight } from "./ToolbarFontWeight";
import { ToolbarHeading } from "./ToolbarHeading";
import { ToolbarImage } from "./ToolbarImage";
import { ToolbarLineHeight } from "./ToolbarLineHeight";
import { ToolbarLink } from "./ToolbarLink";
import { ToolbarPreview } from "./ToolbarPreview";
import { ToolbarScreen } from "./ToolbarScreen";
import { ToolbarTable } from "./ToolbarTable";
import { ToolbarTextAlign } from "./ToolbarTextAlign";
import { ToolbarTextStyle } from "./ToolbarTextStyle";
import { ToolbarTooltip } from "./ToolbarTooltip";

export const Toolbar = ({ editor }: { editor: Editor }) => {
  return (
    <Card
      className="flex flex-wrap items-center gap-2 rounded-md p-3"
      id="text-editor--toolbar"
    >
      <ToolbarFontFamily editor={editor} />
      <ToolbarHeading editor={editor} />

      <Separator orientation="vertical" className="h-7" />

      <ToolbarFontSize editor={editor} />

      <Separator orientation="vertical" className="h-7" />

      <ToolbarTextStyle editor={editor} />

      <Separator orientation="vertical" className="h-7" />

      <ToolbarTextAlign editor={editor} />
      <ToolbarFontWeight editor={editor} />
      <ToolbarLineHeight editor={editor} />

      <Separator orientation="vertical" className="h-7" />

      <ToolbarColor editor={editor} />
      <ToolbarBackgroundColor editor={editor} />

      <Separator orientation="vertical" className="h-7" />

      <ToolbarLink editor={editor} />
      <ToolbarImage editor={editor} />
      <ToolbarTable editor={editor} />

      {toolbarButtons(editor).map(
        ({ key, label, active, action, disable, ...item }) => (
          <Button
            type="button"
            key={key}
            variant={active ? "default" : "outline"}
            size={item.icon ? "icon" : "default"}
            onClick={() => action && action()}
            disabled={disable}
            className={cn("h-8", item.icon && "w-8")}
          >
            {item.icon ? <item.icon className="h-4 w-4" /> : label}
          </Button>
        ),
      )}

      <ToolbarPreview editor={editor} />
      <ToolbarScreen />

      <ToolbarTooltip editor={editor} />
    </Card>
  );
};
