import { DatePicker } from "@/components/common";
import {
  Button,
  buttonVariants,
  Card,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  Input,
} from "@/components/resources";
import { PostsService } from "@/services";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface SearchDataProps {
  selected: number[];
  setSelected: Dispatch<SetStateAction<number[]>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  fromDate: Date | undefined;
  setFromDate: Dispatch<SetStateAction<Date | undefined>>;
  toDate: Date | undefined;
  setToDate: Dispatch<SetStateAction<Date | undefined>>;
  openDialogDelete: boolean;
  setOpenDialogDelete: Dispatch<SetStateAction<boolean>>;
  fetchPosts: (
    page?: number,
    search?: string,
    from?: Date | undefined,
    to?: Date | undefined
  ) => Promise<void>;
  categoryId: 1 | 2;
}

export const SearchData = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setSearchText,
  selected,
  setSelected,
  setCurrentPage,
  openDialogDelete,
  setOpenDialogDelete,
  searchText,
  fetchPosts,
  categoryId,
}: SearchDataProps) => {
  const handleSearch = async () => {
    setCurrentPage(1);
    fetchPosts(1, searchText, fromDate, toDate);
  };

  const handleDeleteMultilple = async () => {
    await PostsService.deleteMultiplePost(selected);
    setCurrentPage(1);
    fetchPosts(1, searchText, fromDate, toDate);
    setOpenDialogDelete(false);
    setSelected([]);
  };
  return (
    <>
      <Card className="p-4 flex items-center gap-3 flex-wrap">
        <span className="font-bold mr-3">기간 검색</span>
        <DatePicker value={fromDate} onChange={setFromDate} toDate={toDate} />
        <span>~</span>
        <DatePicker value={toDate} onChange={setToDate} fromDate={fromDate} />
        <Button onClick={handleSearch}>검색</Button>
        <sub className="text-gray-500 text-sm">
          * 발행일 기준으로 기간을 설정하여 검색할 수 있습니다.
        </sub>
      </Card>

      <div className="flex gap-4 justify-between">
        <div className="flex gap-2 w-full">
          <div className="w-full max-w-64">
            <Input
              placeholder="제목, 내용으로 검색"
              onChange={(e) => {
                if (e.target.value) {
                  setSearchText(e.target.value);
                } else {
                  handleSearch();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </div>
          <Button onClick={handleSearch}>검색</Button>
        </div>
        <div className="flex gap-4">
          <Button
            disabled={selected.length === 0}
            onClick={() => setOpenDialogDelete(true)}
          >
            삭제
          </Button>
          <Link
            to={categoryId === 2 ? "/news/organization/create" : "/news/create"}
            className={buttonVariants()}
          >
            새로운 기사 작성하기
          </Link>
        </div>
      </div>
      <Dialog open={openDialogDelete} onOpenChange={setOpenDialogDelete}>
        <DialogContent className="sm:max-w-[425px]">
          <div>
            <p>선택한 기사를 삭제 하시겠습니까?</p>
          </div>
          <DialogFooter className="mt-5">
            <DialogClose asChild>
              <Button type="button" variant="secondary" className="w-20">
                취소
              </Button>
            </DialogClose>

            <Button
              type="submit"
              onClick={handleDeleteMultilple}
              variant="submit"
              className="w-20"
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
