import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/userSlice";
import configurationSlice from "./features/configurationSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    configuration: configurationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
