import {
  FONT_SIZE_DEFAULT,
  FONT_SIZE_MOBILE,
  FONT_SIZE_TABLET,
} from "../constants";

export const adjustFontSize = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const elements = doc.querySelectorAll('[style*="font-size"]');

  elements.forEach((element) => {
    if (element instanceof HTMLElement) {
      const fontSize = element.style.fontSize;
      const size = parseFloat(fontSize);

      element.style.setProperty(
        "--editor-font-size-mobile",
        `${(size * (FONT_SIZE_MOBILE / FONT_SIZE_DEFAULT)).toFixed(2)}px`
      );
      element.style.setProperty(
        "--editor-font-size-tablet",
        `${(size * (FONT_SIZE_TABLET / FONT_SIZE_DEFAULT)).toFixed(2)}px`
      );
      element.style.setProperty("--editor-font-size-desktop", `${size}px`);

      element.style.fontSize = "";
    }
  });

  return doc.body.innerHTML;
};

export const insertBreakOutput = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const paragraphs = doc.querySelectorAll("p");

  paragraphs.forEach((paragraph) => {
    const content = paragraph.innerHTML.trim();

    if (content === "" && !content.includes("<br>")) {
      paragraph.innerHTML = content + "<br>";
    }
  });

  return doc.body.innerHTML;
};
