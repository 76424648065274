import { EditorContent, useEditor } from "@tiptap/react";
import { Dispatch, SetStateAction } from "react";

import { Toolbar } from "./components";
import { extensions } from "./helper/extensions";

interface TextEditorProps {
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
}

export const TextEditor = ({ value, setValue }: TextEditorProps) => {
  const editor = useEditor({
    extensions,
    content: value,
    editorProps: {
      attributes: {
        id: "text-editor--input",
        class:
          "h-[500px] overflow-y-auto w-full rounded-md border border-input bg-background px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      },
    },
    onUpdate: ({ editor }) => {
      setValue && setValue(editor.getHTML());
    },
    parseOptions: {
      preserveWhitespace: true,
    },
  });

  return (
    <div className="space-y-2 bg-background" id="text-editor">
      {editor && (
        <>
          <Toolbar editor={editor} />
          <EditorContent editor={editor} />
        </>
      )}
    </div>
  );
};
